import React, { useEffect, useMemo, useState } from "react";
// import {
//     DataGrid,
//     GridColDef,
//     GridSelectionModel,
//     GridRowId,
// } from "@mui/x-data-grid";
import { DataGridPro, GridColDef, GridInputSelectionModel, GridRowId, GridRowModesModel, GridSelectionModel, GridSortModel } from "@mui/x-data-grid-pro";

import { RiExpandUpDownLine } from "react-icons/ri";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    SelectChangeEvent,
} from "@mui/material";
import moment from "moment";
import useAuth from "../../context/AuthProvider";

import { DiligentSearchDocument, TaxTransactionReportModel_Resp, TaxTransactionReportModel } from "../../models/TaxTransactionReportModel";
import { AddEditDiligentSearh } from "../AddEdit/AddEditDiligentSearch";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
//import { GenericStyledDropZone } from "../AddEdit/AddEditResubmitSingleDocument";
import { useDropzone } from "react-dropzone";
import { config } from '../../Constants';
import Swal from "sweetalert2";
import { minHeight } from "@mui/system";
import { getRowsPerPageOptions } from "../AddEdit/CustomFunctions";
var formData = new FormData();
interface Iprops {
    ReportModuleCL: TaxTransactionReportModel_Resp;
    fetchDataMain: (
        action: string,
        pageSize: number,
        currentPage: number,
        completedPage: boolean
    ) => void;
    selectedButton: number,
    setSelectedRows: React.Dispatch<React.SetStateAction<TaxTransactionReportModel[] | undefined>>
    setCurrentPage:  React.Dispatch<React.SetStateAction<number>>
    setPageSize:  React.Dispatch<React.SetStateAction<number>>
    _currentPage: number
    _pageSize: number
    completedPage: boolean
    selectedRows: TaxTransactionReportModel[] | undefined
}

export const DiligentSearchList: React.FC<Iprops> = ({
    ReportModuleCL,
    fetchDataMain,
    selectedButton,
    setSelectedRows,
    setCurrentPage,
    setPageSize,
    _currentPage,
    _pageSize,
    completedPage,
    selectedRows
}) => {

    const { user } = useAuth();
    const token = user?.token;

    const fetchData = (customerPolicyId: number) => {

        fetch(config.url.API_URL + `/DiligentSearchReport/getDiligentSearchDocument?customerPolicyId=${customerPolicyId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                
                // Do something with the response
                setDiligentDocument(res.data);
            }).catch((error) => {

                console.log(error)

            });


    }

    const initialLBData: TaxTransactionReportModel_Resp = {
        getOutstandingFilingsReport_Respdata: [
            {
                needDiligentSearch: "",
                diligentSearchCompleted: ""
            }
        ],
        totalCount: 0,
        needDiligentSearch: undefined,
        diligentSearchCompleted: undefined,
        dateCompleted: undefined
    };

    const [loading, setloading] = React.useState(false);

    const [Open, setOpen] = React.useState(false);
    const [openDoc, setOpenDoc] = React.useState(false);
    const [showDoc, setShowDoc] = React.useState(false);
    const [ID, setID] = React.useState();
    const [Formdata, setFormData] = React.useState(initialLBData);
    const [DiligentDocument, setDiligentDocument] = React.useState<DiligentSearchDocument[]>();

    const handleClose = () => {

        setOpen(false);
        setOpenDoc(false);
        setShowDoc(false)
        // fetchData();
    };
    const handleDocClose = () => {

        setOpen(false);
        setOpenDoc(false);
        setShowDoc(false)
        // fetchData();
    };
    function OpneShowDocument(id: any) {

        setID(id);
        setShowDoc(true);
        fetchData(id);
    }
    const handleSubmit = () => {
        const action = "getOutstandingDiligentSearchReportData";
        fetchDataMain(action, _pageSize, _currentPage,completedPage);
        setOpen(false);
    };
    const handleClickOpen = () => {
        setFormData(initialLBData);
        setOpen(true);
    };

    const handleEdit = (ILobData: TaxTransactionReportModel_Resp) => {

        setFormData(ILobData);
        setOpen(true);

    };

    function UploadDocument(id: any) {
        setID(id);
        setOpenDoc(true);
    }

    const columns: GridColDef[] = selectedButton === 1 ? [

        {
            field: "homeState", headerName: "HomeState", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.5,
        },

        {
            field: "insuredName", headerName: "InsuredName", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .8,
        },

        {
            field: "policyNumber", headerName: "PolicyNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .7,
        },
        {
            field: "invoiceNumber", headerName: "InvoiceNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .7,
        },

        {
            field: "needDiligentSearch", headerName: "NeedDiligentSearch", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .6,
        },
        {
            field: "diligentSearchNotes", headerName: "DiligentSearchNotes", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },
          
        {
            field: "suspended", headerName: "Suspended", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.5,
        },

        {
            field: "transactionType", headerName: "TransactionType", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .8,
        },

        {
            field: "carrier", headerName: "Carrier", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },


        {
            field: "naic", headerName: "NAIC", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },

        
        {
            field: "policyEffDate", headerName: "PolicyEffDate", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .5,
        },


        {
            field: "policyExpDate", headerName: "PolicyExpDate", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .5,
        },

        {
            field: "grossPremium", headerName: "GrossPremium", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .6,
        },
        {
            field: "client", headerName: "Client", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },
        {
            field: 'stateRuleMatrixId', flex: 1, headerName: "Edit", hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: 'center', headerAlign: 'center', renderCell: (params) => (<div>
                {/* {selectedButton == 1 && */}
                <IconButton
                    color="primary"
                    onClick={() => { handleEdit(params.row) }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </IconButton>
                <IconButton
                    color="primary"
                    onClick={() => { OpneShowDocument(params.row.customerpolicyid) }}
                >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"></path></svg>
                </IconButton>
               
                <IconButton
                    className="btn2 btn-primary  m-1 "
                    style={{ float: 'right', color: "white", margin: '41px' }}

                
                    onClick={() => { UploadDocument(params.row.customerpolicyid) }}
                    title="Upload documents"
                >
                    <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25M12.75 6L9 2.25M9 2.25L5.25 6M9 2.25V11.25" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </IconButton >


            </div>)
        },

    ] : selectedButton === 2 ? [
   

        {
            field: "homeState", headerName: "HomeState", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.5,
        },

        {
            field: "insuredName", headerName: "InsuredName", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .8,
        },

        {
            field: "policyNumber", headerName: "PolicyNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .7,
        },
        {
            field: "invoiceNumber", headerName: "InvoiceNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .7,
        },

        {
            field: "needDiligentSearch", headerName: "NeedDiligentSearch", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .6,
        },
        {
            field: "diligentSearchNotes", headerName: "DiligentSearchNotes", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },
        {
            field: "diligentSearchCompleted", headerName: "DiligentSearchCompleted", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 1,
        },
        {
            field: "dateCompleted", headerName: "DateCompleted", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            renderCell: (params) =>
                (
                  <div>
                    {(params.row.dateCompleted != null && params.row.dateCompleted != '') ? moment(params.row.dateCompleted).format("MM/DD/YYYY") : ""}
                  </div>
                ), align: "center", flex: 1,
        },         
        {
            field: "suspended", headerName: "Suspended", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.5,
        },

        {
            field: "transactionType", headerName: "TransactionType", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .8,
        },

        {
            field: "carrier", headerName: "Carrier", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },


        {
            field: "naic", headerName: "NAIC", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 0.4,
        },

        
        {
            field: "policyEffDate", headerName: "PolicyEffDate", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .5,
        },


        {
            field: "policyExpDate", headerName: "PolicyExpDate", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .5,
        },

        {
            field: "grossPremium", headerName: "GrossPremium", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .6,
        },
        {
            field: "client", headerName: "Client", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: .9,
        },
        {
            field: 'stateRuleMatrixId', flex: 1, headerName: "Edit", hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: 'center', headerAlign: 'center', renderCell: (params) => (<div>
                <IconButton
                    color="primary"
                    onClick={() => { OpneShowDocument(params.row.customerpolicyid) }}
                >
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> */}
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"></path></svg>
                </IconButton>


            </div>)
        },

    ]: [];
    const onRowsSelectionHandler = (ids: GridSelectionModel) => {
      const selectedIDs = new Set(ids)
      const selectedRowData = ReportModuleCL.getOutstandingFilingsReport_Respdata.filter(
        (row) => selectedIDs.has(row?.rowNum ?? "")
      )
      setSelectedRows(selectedRowData)
    }

    const handleNextPreviousPage = (newPage: number) => {
        setloading(true);
        setCurrentPage(newPage);
        const action = "getOutstandingDiligentSearchReportData";
        fetchDataMain(action, _pageSize, newPage,completedPage);
        setloading(false);
    };

    const handlePageSize = (newPageSize: number) => {
        setPageSize(newPageSize);
        const action = "getOutstandingDiligentSearchReportData";
        fetchDataMain(action, newPageSize, _currentPage,completedPage);
    };

    function EditToolbar() {
        return (
            <React.Fragment>
                <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                    {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div style={{ height: "58vh", overflowX: "scroll" }}>
                <DataGridPro
                    getRowId={(r) => r.rowNum}
                    rows={ReportModuleCL.getOutstandingFilingsReport_Respdata}
                    columns={columns}
                    pageSize={_pageSize}
                    pagination
                    checkboxSelection={selectedButton == 1}
                    selectionModel={selectedRows?.map((item) => item.rowNum).filter((rowNum): rowNum is Exclude<typeof rowNum, undefined> => rowNum !== undefined) || []}
                    onSelectionModelChange={
                        (newRowSelectionModel) => {
                            onRowsSelectionHandler(newRowSelectionModel)
                          }
                      }
                    rowCount={ReportModuleCL.totalCount}
                    rowsPerPageOptions={getRowsPerPageOptions(ReportModuleCL?.totalCount, _pageSize)}
                    paginationMode="server"
                    onPageChange={(newPage) => handleNextPreviousPage(newPage + 1)}
                    onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
                    style={{ textAlign: "center" }}
                    // checkboxSelection={
                    //     user?.userRoles.split(",").includes("Super Admin") ? true : false
                    // }
                    components={{
                        Toolbar: EditToolbar,
                    }}
                    sx={{
                        width: "170vw",
                        "& .MuiDataGridPro-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                        },
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px",

                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    columnVisibilityModel={{
                        paymentDate: user?.userRoles.split(",").includes("Super Admin")
                            ? true
                            : false,
                        paymentAmount: user?.userRoles.split(",").includes("Super Admin")
                            ? true
                            : false,
                        lineOfBusiness: user?.userRoles.split(",").includes("Super Admin")
                            ? true
                            : false,
                    }}
                />
                <AddEditDiligentSearh handleClose={handleClose} fetchDataMain={fetchDataMain} Open={Open} StateRuleMatrixCL={Formdata} handleSubmit={handleSubmit} initValues={initialLBData} selectedButton={selectedButton} completedPage={completedPage} />
                <AddEditDocument open={openDoc} handleClose={handleClose} customerPolicyId={ID}></AddEditDocument>
                <ShowAllDocument open={showDoc} DiligentDocument={DiligentDocument} handleDocClose={handleDocClose} customerPolicyId={ID}></ShowAllDocument>
            </div>

        </React.Fragment>
    );
};

export function GenericStyledDropZone(props: any) {
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject,

    } = useDropzone({
        maxFiles: 10,
        // accept: { 'application/pdf': ['.pdf'], 'application/word': ['.word'] },
        accept: { 'application/pdf': ['.pdf'], 'image/*': [], 'application/msg': ['.msg'], 'application/word': ['.word'] },
        onDrop:
            (files) => {
                // debugger
                formData.delete("document");

                files.map((pp, key) =>
                    formData.append("document" + key, files[key])
                )
            }
    });

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };
    const baseStyle = {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderWidth: 1,
        borderRadius: 8,
        borderColor: '#162E3C80',
        borderStyle: 'dashed',

        backgroundColor: 'rgba(22, 46, 60, 0.10)',
        color: '#162E3C',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const files = acceptedFiles.map
        (
            file =>
            (
                <React.Fragment >
                    <span style={{ fontSize: "14px" }}>
                        {file.name.length > 35 ? file.name.substring(0, 35) + '...' : file.name}
                    </span>
                    {/* {file.name} - {(file.size / 1024).toFixed(2)} kb */}

                </React.Fragment>
            )
        );

    return (
        <React.Fragment>
            <div className=" box-style col-lg-12">
                <div {...getRootProps({ style })}>

                    <input {...getInputProps()} name={'diligentdocument'} multiple />
                    <p style={{ fontSize: "16px", color: "#162E3C", fontWeight: "600" }}>Drop diligent search document</p>
                </div>
                <span >
                    <p style={{ height: "20px", textAlign: "start", margin: 2 }}>{files}</p>
                </span>
            </div>
        </React.Fragment>
    );
}


interface props {
    open: boolean
    handleClose: () => void,
    customerPolicyId: any
}

const AddEditDocument: React.FC<props> = ({ open, handleClose, customerPolicyId }) => {

    const initialValues: {} = {}
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;

    useEffect(() => {

    }, [])
    return (

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
            <div className="card-body"> <DialogTitle className='card-title'>Upload Document</DialogTitle></div>
            <div className="">
                <React.Fragment>
                    <Formik initialValues={initialValues}
                        validateOnChange={true}

                        onSubmit={(data, { setSubmitting }) => {
                            let count = 0
                            formData.forEach(element => {
                                count = count + 1;
                            });

                            if (count < 1) {
                                alert("select a document");
                                setSubmitting(false);
                                return;
                            }
                            formData.append('CustomerPolicyId', customerPolicyId);
                            formData.append("data", JSON.stringify(data));
                            setloading(true);
                            fetch(
                                config.url.API_URL + `/DiligentSearchReport/UploadDocument/`,
                                {
                                    method: 'POST',
                                    headers: new Headers({
                                        // 'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    }),
                                    body: formData  // <-- Post parameters

                                })
                                .then((response) => {
                                    if (response.ok) {
                                        return response.json();

                                    }
                                    throw new Error('Something went wrong');
                                })
                                .then((res) => {
                                    // Do something with the response
                                    setSubmitting(false);
                                    handleClose();
                                    setloading(false);
                                    console.log(res);
                                    Swal.fire(
                                        'Success',
                                        res.data,
                                        'info'
                                    )

                                    formData = new FormData();

                                }).catch((error) => {
                                    setSubmitting(false);
                                    alert("There was an error saving the Document");
                                    formData = new FormData();
                                    console.log(error)
                                    setloading(false);
                                    handleClose();
                                });
                        }
                        } >
                        {({ values, isSubmitting }) =>
                        (
                            <Form>
                                {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                <DialogContent>
                                    <div className="card-body">
                                        <div className="row row-cols-1 row-cols-md-3 g-1 " style={{ display: "flex", alignItems: "center" }}>
                                            {
                                                <div className='col-lg-12 col-sm-12 gx-5'>
                                                    <div className="card col-lg-12 m-2">
                                                        <div className=" ">
                                                            <GenericStyledDropZone Key={1} />
                                                        </div>
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                    </div>

                                    <div className='card-body'>
                                        <div className="row row-cols-1 row-cols-md-3 g-1 m-0 ">
                                            <div className="col-lg-12 m-2 ">
                                                <div className="d-flex ">
                                                    <div className="me-3">
                                                        <button type="submit" className=" active-btn-blue me-2 " disabled={isSubmitting}>Upload</button>
                                                    </div>
                                                    <div className="me-3">
                                                        <button className=" active-btn-white" type='button' onClick={handleClose}>Cancel</button>
                                                    </div>
                                                    {/* <div>   {loading && <CircularProgress />}</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </DialogContent>
                            </Form>
                        )}
                    </Formik>
                </React.Fragment>
            </div>
        </Dialog>
    );
}

interface propsDoc {
    open: boolean
    handleDocClose: () => void,
    customerPolicyId: any,
    DiligentDocument: DiligentSearchDocument[] | undefined
}
const ShowAllDocument: React.FC<propsDoc> = ({ open, handleDocClose, customerPolicyId, DiligentDocument }) => {

    const initialValues: {} = {}
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;

    useEffect(() => {

    }, [])
    return (

        <Dialog open={open} onClose={handleDocClose} fullWidth maxWidth={'md'}>
            <div > <DialogTitle className='card-title'>All Document</DialogTitle></div>
            <div className="">
                <React.Fragment>
                    <DialogContent>
                    {DiligentDocument && DiligentDocument.length > 0 ?
                            <table className="table">
                                <tbody>

                                    {DiligentDocument?.map((value, key) =>
                                        <Grid gridRow={2} container spacing={2}>
                                            <tr key={key}>
                                                <td>
                                                    <div style={{ maxWidth: '920px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        <Grid>

                                                            <Link href={value.blob_Address} sx={{ textDecoration: "none " }} target="_blank">
                                                                {value.documentName.substring(0, 99)}
                                                                <svg width="20" height="20" viewBox="0 0 22 22" fill="none" className="" xmlns="http://www.w3.org/2000/svg" ><path d="M19.25 13.75V17.4167C19.25 17.9029 19.0568 18.3692 18.713 18.713C18.3692 19.0568 17.9029 19.25 17.4167 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V13.75M6.41667 9.16667L11 13.75M11 13.75L15.5833 9.16667M11 13.75V2.75" stroke="#02A4E3" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                                                            </Link>
                                                        </Grid>

                                                    </div>
                                                </td>
                                            </tr>
                                        </Grid>
                                    )}
                                </tbody>
                            </table>
                            : <span>No supportive document found for this transaction.</span> }

                    </DialogContent>
                    <DialogActions>
                                <button className=" btn-2 btn d-flex btn-primary search-btn  " type="button" onClick={handleDocClose}  >Cancel</button>
                              
                            </DialogActions>
                </React.Fragment>
            </div>
        </Dialog>
    );
}



