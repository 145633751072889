import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, FormControlLabel, Checkbox, TextField, MenuItem, Select } from '@mui/material';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface LicenseesContactInformation {
  partnerLincenseeInformationId: number;
  partnersId: number;
  cancellationDate: string;
  entity: boolean;
  fein: string;
  licenseNumber: string;
  licenseState: string;
  licensee: string;
  mainLicensee: boolean;
  npn: string;
  title: string;
}
interface Iprops {
  licenseesContactInformation: LicenseesContactInformation,
  handleClose: () => void,
  Open: boolean,
  handleSubmit: () => void
  initValues: LicenseesContactInformation
  setLicenseesContactArry: React.Dispatch<React.SetStateAction<LicenseesContactInformation[]>>
  licenseesContactArry: LicenseesContactInformation[]
}

export const AddEditLicenseContact: React.FC<Iprops> = ({
  handleClose, Open, licenseesContactInformation, handleSubmit, initValues, setLicenseesContactArry, licenseesContactArry
}) => {
  const initialValues: LicenseesContactInformation = initValues ? licenseesContactInformation : initValues;
  const { user } = useAuth();
  const token = user?.token;

  const initialStatedata = { licenseState: 0, stateName: "", postalAbbv: '' };
  const [States, setStates] = useState([initialStatedata]);

  function fetchStateData() {
    fetch(config.url.API_URL + "/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        if (responseJson.data != null) {
          setStates(responseJson.data);
        }

        console.log("statesDAta", responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchStateData();
  }, []);



  const [loading, setloading] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Dialog open={Open} onClose={handleClose}>
      <DialogTitle className='card-title'>Licensee Contact</DialogTitle>

      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        // validationSchema={PartnersModel_VS}
        onSubmit={(data, { setSubmitting, setErrors }) => {
          debugger;
          setloading(true);
          if (data.partnerLincenseeInformationId === 0) {
            setLicenseesContactArry(oldArray => [...oldArray, data]);
          } else {
            let AllRec = licenseesContactArry
            AllRec.forEach((x, index) => {
              debugger;
              if (x.partnerLincenseeInformationId === data.partnerLincenseeInformationId) {
                AllRec[index].cancellationDate = data.cancellationDate;
                AllRec[index].entity = data.entity;
                AllRec[index].fein = data.fein;
                AllRec[index].licenseNumber = data.licenseNumber;
                AllRec[index].licenseState = data.licenseState;
                AllRec[index].licensee = data.licensee;
                AllRec[index].mainLicensee = data.mainLicensee;
                AllRec[index].npn = data.npn;
                AllRec[index].title = data.title;
              }
            });
            setLicenseesContactArry(AllRec)

          }
          handleSubmit();
          //     console.log(PartnerTaxesAndFeeCL);
          }
        }
      >
        {({ values, isSubmitting, errors, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                {/* Title Field */}
                <Grid item xs={12}>
                  <FormControl error={!!errors.title} sx={{ width: '100%' }}>
                    <FMTextField
                      label="Title"
                      name="title"
                      value={initialValues.title}
                      onChange={(e: any) => {
                        initialValues.title = e.target.value;
                        setFieldValue("title", e.target.value);
                      }}
                      // onBlur={handleBlur}
                      fullWidth
                      error={!!errors.title}
                      helperText={errors.title}
                    />
                  </FormControl>
                </Grid>

                {/* License State Dropdown */}
                <Grid item xs={6}>
                  <FormControl error={!!errors.licenseState} sx={{ width: '100%' }}>
                    <Select
                      value={initialValues.licenseState}
                      id="test-select-label"
                      label={null}
                      onChange={(e) => {
                        setFieldValue("licenseState", e.target.value);
                        initialValues.licenseState = e.target.value;
                      }}
                      name="licenseState"
                      className="border-round custom-menu-item"
                      IconComponent={ExpandMoreIcon}
                      displayEmpty
                    >
                      <MenuItem key={0} value={0}>
                        Select State{" "}
                      </MenuItem>
                      {States.map((state, key) => (
                        <MenuItem key={key} value={state.postalAbbv}>
                          {state.stateName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.licenseState}</FormHelperText>
                  </FormControl>
                </Grid>

                {/* Cancellation Date Field */}
                <Grid item xs={6}>
                  <FormControl error={!!errors.cancellationDate} sx={{ width: '100%' }}>
                  <DatePicker
                    inputFormat="MM/DD/YYYY"
                    label="Cancellation Date"
                    className="select-input-border text-input-box"
                    value={(initialValues.cancellationDate == '') ? new Date(initialValues.cancellationDate) : initialValues.cancellationDate}
                    onChange={(v) => {
                      if (v) {
                        var formattedDate = ''
                        // Convert the selected date to "YYYY-MM-DDTHH:mm:ss" format
                        try{
                          formattedDate = new Date(v).toISOString().split('.')[0];  // Remove milliseconds
                        } catch{
                          formattedDate = v.toLocaleString()
                        }
                        initialValues.cancellationDate = formattedDate;
                        setFieldValue("cancellationDate", formattedDate);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        id="cancellationDate"
                        name="cancellationDate"
                      />
                    )}
                  />
                  </FormControl>
                </Grid>

                {/* Licensee Field */}
                <Grid item xs={6}>
                  <FormControl error={!!errors.licensee} sx={{ width: '100%' }}>
                    <FMTextField
                      label="Licensee"
                      name="licensee"
                      value={initialValues.licensee}
                      onChange={(e: any) => {
                        initialValues.licensee = e.target.value;
                        setFieldValue("licensee", e.target.value);
                      }}
                      // onBlur={handleBlur}
                      fullWidth
                      error={!!errors.licensee}
                      helperText={errors.licensee}
                    />
                  </FormControl>
                </Grid>
          
                {/* License Number Field */}
                <Grid item xs={6}>
                  <FormControl error={!!errors.licenseNumber} sx={{ width: '100%' }}>
                    <FMTextField
                      label="License Number"
                      name="licenseNumber"
                      value={initialValues.licenseNumber}
                      onChange={(e: any) => {
                        initialValues.licenseNumber = e.target.value;
                        setFieldValue("licenseNumber", e.target.value);
                      }}
                      // onBlur={handleBlur}
                      fullWidth
                      error={!!errors.licenseNumber}
                      helperText={errors.licenseNumber}
                    />
                  </FormControl>
                </Grid>
          
                {/* NPN Field */}
                <Grid item xs={6}>
                  <FormControl error={!!errors.npn} sx={{ width: '100%' }}>
                    <FMTextField
                      label="NPN"
                      name="npn"
                      value={initialValues.npn}
                      onChange={(e: any) => {
                        initialValues.npn = e.target.value;
                        setFieldValue("npn", e.target.value);
                      }}
                      // onBlur={handleBlur}
                      fullWidth
                      error={!!errors.npn}
                      helperText={errors.npn}
                    />
                  </FormControl>
                </Grid>
          
                {/* FEIN Field */}
                <Grid item xs={6}>
                  <FormControl error={!!errors.fein} sx={{ width: '100%' }}>
                    <FMTextField
                      label="FEIN"
                      name="fein"
                      value={initialValues.fein}
                      onChange={(e: any) => {
                        initialValues.fein = e.target.value;
                        setFieldValue("fein", e.target.value);
                      }}
                      // onBlur={handleBlur}
                      fullWidth
                      error={!!errors.fein}
                      helperText={errors.fein}
                    />
                  </FormControl>
                </Grid>

                {/* Main License (Boolean Checkbox) */}
                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={initialValues.mainLicensee}
                          onChange={(e) => {
                            initialValues.mainLicensee = e.target.checked;
                            setFieldValue("mainLicensee", e.target.checked)
                          }}
                          name="mainLicense"
                          color="primary"
                        />
                      }
                      label="Main License"
                    />
                  </FormControl>
                </Grid>
          
                {/* Entity (Boolean Checkbox) */}
                <Grid item xs={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={initialValues.entity}
                          // onChange={(e) => setFieldValue("entity", e.target.checked ? true : false)}
                          onChange={(e) => {
                            initialValues.entity = e.target.checked;
                            setFieldValue("entity", e.target.checked)
                          }}
                          name="entity"
                          color="primary"
                        />
                      }
                      label="Entity"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
          
            <DialogActions>
              <button
                className="btn-2 btn d-flex btn-primary search-btn"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary active-btn-blue search-btn"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </DialogActions>
          </Form>        
        )}
      </Formik>
    </Dialog>
    </LocalizationProvider>
  );

}
