import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import { FieldAttributes, useField } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { number, string } from "yup";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { LineOfBusinessModel } from "../../models/LineOfBusinessModel";
import Multiselect from "multiselect-react-dropdown";
import { selectOptions } from "../../models/UsersModel";
import { useParams } from "react-router-dom";
import { stateOptions } from "../AddEdit/AddEditPartners";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export const FMSelectStateField: React.FC<FieldAttributes<SelectProps>> = ({
  placeholder,
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} fullWidth>
      {/* <InputLabel id="demo-simple-select-error-label">State</InputLabel> */}
      <Select {...field} labelId="demo-simple-select-error-label" label={null}
        className="border-round custom-menu-item"
        IconComponent={ExpandMoreIcon}
        displayEmpty >
        <MenuItem value=""> Select State </MenuItem>
        <MenuItem value="Alabama">Alabama</MenuItem>
        <MenuItem value="Alaska">Alaska</MenuItem>
        <MenuItem value="Arizona">Arizona</MenuItem>
        <MenuItem value="Arkansas">Arkansas</MenuItem>
        <MenuItem value="California">California</MenuItem>
        <MenuItem value="Colorado">Colorado</MenuItem>
        <MenuItem value="Connecticut">Connecticut</MenuItem>
        <MenuItem value="Delaware">Delaware</MenuItem>
        <MenuItem value="Florida">Florida</MenuItem>
        <MenuItem value="Georgia">Georgia</MenuItem>
        <MenuItem value="Hawaii">Hawaii</MenuItem>
        <MenuItem value="Idaho">Idaho</MenuItem>
        <MenuItem value="Illinois">Illinois</MenuItem>
        <MenuItem value="Indiana">Indiana</MenuItem>
        <MenuItem value="Iowa">Iowa</MenuItem>
        <MenuItem value="Kansas">Kansas</MenuItem>
        <MenuItem value="Kentucky">Kentucky</MenuItem>
        <MenuItem value="Louisiana">Louisiana</MenuItem>
        <MenuItem value="Maine">Maine</MenuItem>
        <MenuItem value="Maryland">Maryland</MenuItem>
        <MenuItem value="Massachusetts">Massachusetts</MenuItem>
        <MenuItem value="Michigan">Michigan</MenuItem>
        <MenuItem value="Minnesota">Minnesota</MenuItem>
        <MenuItem value="Mississippi">Mississippi</MenuItem>
        <MenuItem value="Missouri">Missouri</MenuItem>
        <MenuItem value="Montana">Montana</MenuItem>
        <MenuItem value="Nebraska">Nebraska</MenuItem>
        <MenuItem value="Nevada">Nevada</MenuItem>
        <MenuItem value="New Hampshire">New Hampshire</MenuItem>
        <MenuItem value="New Jersey">New Jersey</MenuItem>
        <MenuItem value="New Mexico">New Mexico</MenuItem>
        <MenuItem value="New York">New York</MenuItem>
        <MenuItem value="North Carolina">North Carolina</MenuItem>
        <MenuItem value="North Dakota">North Dakota</MenuItem>
        <MenuItem value="Ohio">Ohio</MenuItem>
        <MenuItem value="Oklahoma">Oklahoma</MenuItem>
        <MenuItem value="Oregon">Oregon</MenuItem>
        <MenuItem value="Pennsylvania">Pennsylvania</MenuItem>
        <MenuItem value="Rhode Island">Rhode Island</MenuItem>
        <MenuItem value="South Carolina">South Carolina</MenuItem>
        <MenuItem value="South Dakota">South Dakota</MenuItem>
        <MenuItem value="Tennessee">Tennessee</MenuItem>
        <MenuItem value="Texas">Texas</MenuItem>
        <MenuItem value="Utah">Utah</MenuItem>
        <MenuItem value="Vermont">Vermont</MenuItem>
        <MenuItem value="Virginia">Virginia</MenuItem>
        <MenuItem value="District of Columbia">District of Columbia</MenuItem>
        <MenuItem value="Washington">Washington</MenuItem>
        <MenuItem value="West Virginia">West Virginia</MenuItem>
        <MenuItem value="Wisconsin">Wisconsin</MenuItem>
        <MenuItem value="Wyoming">Wyoming</MenuItem>
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectTaxTypeField: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} fullWidth  >
      <FormHelperText className='formtexthelper-heading ' >Tax/Fee
      </FormHelperText>
      {/* <InputLabel id="taxtype-select-error-label">Tax/Fee </InputLabel> */}

      <Select {...field} labelId="taxtype-select-error-label" className="border-round main-form-2" IconComponent={ExpandMoreIcon} label={null}>
        <MenuItem value="">Please select </MenuItem>
        <MenuItem value="Estimated Tax">Estimated Fee</MenuItem>
        <MenuItem value="Monthly Report of Business">Monthly Report of Business</MenuItem>
        <MenuItem value="Policy Filing">Policy Filing</MenuItem>
        <MenuItem value="DFS Tax">DFS Tax</MenuItem>
        <MenuItem value="Excess Lines Tax">Excess Lines Tax</MenuItem>
        <MenuItem value="Fire Marshall Tax">Fire Marshall Tax</MenuItem>
        <MenuItem value="FSLSO Service Fee">FSLSO Service Fee</MenuItem>
        <MenuItem value="Municipal Premium Tax">Municipal Premium Tax</MenuItem>
        <MenuItem value="MWUA Policy Fee">MWUA Policy Fee</MenuItem>
        <MenuItem value="SD Fire Property tax">SD Fire Property tax</MenuItem>
        <MenuItem value="SD State Tax">SD State Tax</MenuItem>
        <MenuItem value="SDAL Transaction Fee">SDAL Transaction Fee</MenuItem>
        <MenuItem value="SLAS Transaction Fee">SLAS Transaction Fee</MenuItem>
        <MenuItem value="SLSC">SLSC</MenuItem>
        <MenuItem value="Stamping Fee">Stamping Fee</MenuItem>
        <MenuItem value="State Premium Tax">State Premium Tax</MenuItem>
        <MenuItem value="State Tax">State Tax</MenuItem>
        <MenuItem value="Surcharge">Surcharge</MenuItem>
        <MenuItem value="Surplus Lines Tax">Surplus Lines Tax</MenuItem>
        <MenuItem value="KS Filing Fee">KS Filing Fee</MenuItem>
        <MenuItem value="KY Premium Surcharge Fee"> KY Premium Surcharge Fee </MenuItem>
        <MenuItem value="MD Corporate Amendment Fee">MD Corporate Amendment Fee</MenuItem>
        <MenuItem value="AL Fraud Assessment Fee"> AL Fraud Assessment Fee</MenuItem>
        <MenuItem value="UT Filing Fee">UT Filing Fee</MenuItem>

      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectUserRoleField: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const Roles = [
    { Name: "Admin", value: 1 },
    { Name: "Customer", value: 2 },
    { Name: "FileMuleStaff", value: 3 },
    { Name: "User", value: 4 },
  ];

  // function getStyles(Role: string, personRole: Roles, theme: Theme) {
  //     return {
  //         fontWeight:
  //         personRole.indexOf(personRole) === -1
  //                 ? theme.typography.fontWeightRegular
  //                 : theme.typography.fontWeightMedium,
  //     };
  // }

  const [Role, setRole] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof Role>) => {
    const {
      target: { value },
    } = event;
    setRole(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    // console.log(Role);
    console.log(value);
  };

  return (
    <FormControl error={!!errorText} fullWidth>
      <Select
        id="multiselect"
        multiple
        value={Role}
        onChange={handleChange}
        // input={<OutlinedInput  {...field}  />}
        MenuProps={MenuProps}
      >
        {Roles.map((Role) => (
          <MenuItem
            key={Role.Name}
            value={Role.value}
          // style={getStyles(Role.Name, Role, theme)}
          >
            {Role.Name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectLOBField: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} fullWidth>
      {/* <InputLabel id="taxtype-select-error-label">LOB</InputLabel> */}

      <Select {...field} label={null}
        className="border-round custom-menu-item"
        IconComponent={ExpandMoreIcon}
        displayEmpty labelId="taxtype-select-error-label">
        <MenuItem value="">Please select LOB</MenuItem>
        <MenuItem value="All">All</MenuItem>
        <MenuItem value="Property and Casualty">Property and Casualty</MenuItem>
        <MenuItem value="Property Only">Property Only</MenuItem>
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectRateTypeField: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} fullWidth className="main-form-2" >
      <InputLabel id="taxtype-select-error-label">Rate Type</InputLabel>
      <Select {...field} labelId="Select-Label" label="Rate Type"
        className="border-round main-form-2"
        IconComponent={ExpandMoreIcon}
      >
        {/* <MenuItem value="">Rate Type</MenuItem> */}
        <MenuItem value="0">Percentage</MenuItem>
        <MenuItem value="1">Dollar</MenuItem>
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );

};

export const FMSelectTrueFalse: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  label,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="TrueFlase-simple-select-label">{label}</InputLabel>
      <Select
        {...field}
        id="demo-simple-select"
        labelId="TrueFlase-simple-select-label"
        size="small"
        label={label}
        className="border-round main-form-2"
        IconComponent={ExpandMoreIcon}
        value={field.value || ""}
      >
        {/* <MenuItem value="">Select</MenuItem> */}
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>


  );
};

export const FMSelectActiveInactive: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="State-Select-Label">Status</InputLabel>
      <Select {...field} labelId="Select-Label" label="Status" className="border-round main-form-2"
        IconComponent={ExpandMoreIcon}>
        {/* <MenuItem key={0} value={0}>
          Status
        </MenuItem> */}
        <MenuItem value={true as any}>Active</MenuItem>
        <MenuItem value={false as any}>Inactive</MenuItem>
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};
export const FMSelectEscalation: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} fullWidth>
      <Select {...field} labelId="Select-Label" label="" className="border-round main-form-2"
        IconComponent={ExpandMoreIcon}>
        {/* <MenuItem key={0} value={0}>
          Status
        </MenuItem> */}
        <MenuItem value={0}>None</MenuItem>
        <MenuItem value={1}>1st Escalation</MenuItem>
        <MenuItem value={2}>2nd Escalation</MenuItem>
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};
export const FMSelectPartnerPaymentType: React.FC<
  FieldAttributes<SelectProps>
> = ({ variant, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();

  return (
    <FormControl error={!!errorText} fullWidth>
      {/* <InputLabel id="State-Select-Label">Payment Type</InputLabel> */}
      <Select
        disabled={
          !(
            user?.userRoles.split(",").includes("Super Admin") ||
            user?.userRoles.split(",").includes("MISC Filer")
          )
        }
        {...field}
        labelId="Select-Label"
        className=" custom-menu-item"
        IconComponent={ExpandMoreIcon}
      // label="Payment Type"
      >
        <MenuItem value={true as any}>PayMule Integration</MenuItem>
        <MenuItem value={false as any}>No PayMule Integration</MenuItem>
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectMonth: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="Select-Label">{props.label}</InputLabel>
      <Select {...field} labelId="Select-Label" label="True/False">
        <MenuItem value={0}>Please select Month </MenuItem>
        <MenuItem value={1}>January</MenuItem>
        <MenuItem value={2}>February</MenuItem>
        <MenuItem value={3}>March</MenuItem>
        <MenuItem value={4}>April</MenuItem>
        <MenuItem value={5}>May</MenuItem>
        <MenuItem value={6}>June</MenuItem>
        <MenuItem value={7}>July</MenuItem>
        <MenuItem value={8}>August</MenuItem>
        <MenuItem value={9}>September</MenuItem>
        <MenuItem value={10}>October</MenuItem>
        <MenuItem value={11}>November</MenuItem>
        <MenuItem value={12}>December</MenuItem>
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectModelField: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl error={!!errorText} fullWidth>
      <Select {...field} defaultValue={0} error={false}>
        <MenuItem value={0}>Please select Document Type </MenuItem>
        <MenuItem value={1}>Delaware SL-1923</MenuItem>
        <MenuItem value={2}>Delaware Surplus Lines Notification</MenuItem>
        <MenuItem value={3}>Delaware SL-1917L</MenuItem>
        <MenuItem value={4}>Delaware Property Policy</MenuItem>
        <MenuItem value={5}>Delaware GL Policy</MenuItem>
        <MenuItem value={6}>Connecticut SL8</MenuItem>
        <MenuItem value={7}>Connecticut GL Policy</MenuItem>
        <MenuItem value={8}>Connecticut Property Policy</MenuItem>
        <MenuItem value={9}>Colorado Surplus Lines Notification</MenuItem>
        <MenuItem value={10}>Colorado GL Policy</MenuItem>
        <MenuItem value={11}>California D1</MenuItem>
        <MenuItem value={12}>California SL-1</MenuItem>
        <MenuItem value={13}>California SL-2</MenuItem>
        <MenuItem value={14}>California Surplus Lines Notification</MenuItem>
        <MenuItem value={15}>California GL Policy</MenuItem>
        <MenuItem value={16}>California Property Policy</MenuItem>
        <MenuItem value={17}>Arizona SL Form</MenuItem>
        <MenuItem value={18}>Arizona GL Policy</MenuItem>
        <MenuItem value={19}>Arizona Property Policy</MenuItem>
        <MenuItem value={20}>Arkansas SL Form</MenuItem>
        <MenuItem value={21}>Alabama SL Form</MenuItem>
        <MenuItem value={22}>Arkansas GL Policy</MenuItem>
        <MenuItem value={23}>Arkansas Property Policy</MenuItem>
        <MenuItem value={24}>Alabama Property Policy</MenuItem>
        <MenuItem value={25}>Alabama GL Policy</MenuItem>
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectState: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialdata]);
  const [stateId, setstateId] = useState(0);
  const fetchData = () => {
    fetch(config.url.API_URL + "/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        if (responseJson.data != null) {
          setStates(responseJson.data);
        }

        console.log("statesDAta", responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [loading, setloading] = React.useState(false);
  const stateData = React.useRef(0);
  const handleChange = (event: SelectChangeEvent) => {
    setloading(true);
    setstateId(Number(event.target.value));
    stateData.current = Number(event.target.value);
    setloading(false);
  };
  return (
    <FormControl error={!!errorText} fullWidth>
      {/* <FormHelperText>Select State</FormHelperText> */}
      <InputLabel id="State-Select-Label"></InputLabel>
      <Select
        onChange={handleChange}
        value={stateId.toString()}
        size="small"
        id="test-select-label"
        autoWidth
        label={null}
        className="border-round custom-menu-item"
        IconComponent={ExpandMoreIcon}
        displayEmpty
      >
        <MenuItem value={"0"}>Select State</MenuItem>
        {States?.map((state, key) => (
          <MenuItem
            key={key}
            value={state.stateId}
            className="custom-menu-item"
          >
            {state.stateName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FMSelectMultiState: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialdata]);
  const [stateIds, setstateIds] = useState<Array<string>>([]);
  const fetchData = () => {
    fetch(config.url.API_URL + "/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        if (responseJson.data != null) {
          setStates(responseJson.data);
        }

        console.log("statesDAta", responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [loading, setloading] = React.useState(false);
  const stateData = React.useRef(0);
  const handleChange = (event: SelectChangeEvent<typeof stateIds>) => {
    setloading(true);
    setstateIds(typeof event.target.value === 'string'? event.target.value.split(','):event.target.value);
    stateData.current = Number(event.target.value);
    setloading(false);
  };
  return (
    <FormControl error={!!errorText} fullWidth>
      {/* <FormHelperText>Select State</FormHelperText> */}
      {/* <InputLabel id="State-Select-Label"></InputLabel> */}
      <Select
        multiple
        value={stateIds}
        onChange={handleChange}
        size="small"
        id="test-select-label"
        autoWidth
        label={null}
        className="border-round custom-menu-item"
        IconComponent={ExpandMoreIcon}
        displayEmpty
      >
        {/* <MenuItem value={"0"}>State</MenuItem> */}
        {States?.map((state, key) => (
          <MenuItem
            key={key}
            value={state.stateId}
            className="custom-menu-item"
          >
            {state.stateName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FMSelectStateTesting: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialdata]);

  const handleChange = (event: SelectChangeEvent) => {
    return event;
  };

  const fetchData = () => {
    fetch(config.url.API_URL + "/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setStates(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="State-Select-Label">State</InputLabel>
      <Select {...field} labelId="State-Select-Label" label="State">
        {States.map((state, key) => (
          <MenuItem key={key} value={state.stateId}>
            {" "}
            {state.stateName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectPartners: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { partnersId: 0, customerName: "" };
  const [Partner, setpartnerData] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/Partners/getPartners", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setpartnerData(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="State-Select-Label">Customer/Partner </InputLabel>
      <Select {...field} labelId="State-Select-Label" label="Customer/Partner">
        {Partner.map((Name, key) => (
          <MenuItem key={key} value={Name.partnersId}>
            {" "}
            {Name.customerName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectPayablePayout: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { payablePayoutId: 0, payoutName: "" };
  const [PayablePayout, setPayablePayout] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/Payables/getPayablePayout", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setPayablePayout(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="State-Select-Label">Payout</InputLabel>
      <Select {...field} labelId="State-Select-Label" label="Payout">
        {PayablePayout.map((pp, key) => (
          <MenuItem key={key} value={pp.payablePayoutId}>
            {" "}
            {pp.payoutName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};
export const FMSelectPayablePaymentType: React.FC<
  FieldAttributes<SelectProps>
> = ({ variant, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { payablePaymentTypeId: 0, paymentName: "" };
  const [PayablePaymentType, setPayablePaymentType] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/Payables/getPayablePaymentType", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setPayablePaymentType(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="State-Select-Label">Payment Type</InputLabel>
      <Select {...field} labelId="State-Select-Label" label="Payment Type">
        {PayablePaymentType.map((pp, key) => (
          <MenuItem key={key} value={pp.payablePaymentTypeId}>
            {" "}
            {pp.paymentName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectPayableCategory: React.FC<
  FieldAttributes<SelectProps>
> = ({ variant, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { payableCategoryId: 0, categoryName: "" };
  const [PayableCategory, setPayableCategory] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/Payables/getPayableCategory", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setPayableCategory(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="State-Select-Label">Payable Category</InputLabel>
      <Select {...field} labelId="State-Select-Label" label="Payable Category">
        {PayableCategory.map((pp, key) => (
          <MenuItem key={key} value={pp.payableCategoryId}>
            {" "}
            {pp.categoryName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectPartnerType: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { partnerTypeId: 0, typeName: "" };
  const [PartnerTypeData, setpartnerTypeData] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/PartnerType/getAll", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setpartnerTypeData(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>
      <FormHelperText className="formtexthelper-heading" >Customer Type </FormHelperText>
      {/* <InputLabel id="State-Select-Label">Customer Type</InputLabel> */}
      <Select {...field} labelId="State-Select-Label"
        label={null}
        className="border-round custom-menu-item"
        IconComponent={ExpandMoreIcon}>
        <MenuItem key={0} value={0}>
          Customer Type
        </MenuItem>  {PartnerTypeData.map((Name, key) => (

          <MenuItem key={key} value={Name.partnerTypeId}>
            {" "}
            {Name.typeName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectPartnerIntegrationType: React.FC<
  FieldAttributes<SelectProps>
> = ({ variant, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { partnerIntegrationTypeId: 0, integrationName: "" };
  const [PartnerIntegrationType, setPartnerIntegrationType] = useState([
    initialdata,
  ]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/PartnerIntegrationType/getAllPartners", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setPartnerIntegrationType(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>

      {/* <InputLabel id="State-Select-Label">Integration Type</InputLabel> */}
      <Select
        disabled={
          !(
            user?.userRoles.split(",").includes("Super Admin") ||
            user?.userRoles.split(",").includes("MISC Filer")
          )

        }
        {...field}
        className=" custom-menu-item"
        IconComponent={ExpandMoreIcon}
        displayEmpty
      >
        <MenuItem key={0} value={0}>
          Integration Type
        </MenuItem>
        {PartnerIntegrationType.map((Name, key) => (
          <MenuItem key={key} value={Name.partnerIntegrationTypeId}>
            {" "}
            {Name.integrationName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectPartner: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  className,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { partnersId: 0, customerName: "" };
  const [Partners, setStates] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/Partners/getPartners", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setStates(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl className={className} error={!!errorText} fullWidth sx={{ m: 1 }}>
      <InputLabel id="State-Select-Label">Partner</InputLabel>
      <Select {...field} label="Partner"
        className="border-round main-form-2"
        IconComponent={ExpandMoreIcon}
        labelId="State-Select-Label" value={field.value || ""}>

        {/* <MenuItem value={"0"}>Partner</MenuItem> */}
        {Partners.map((partner, key) => (
          <MenuItem key={key} value={partner.partnersId}>
            {" "}
            {partner.customerName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectUserFillingState: React.FC<
  FieldAttributes<SelectProps>
> = ({ variant, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/FillingsByUser/getUserFillingStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setStates(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="State-Select-Label">State</InputLabel>
      <Select {...field} labelId="State-Select-Label" label="State">
        {States.map((state, key) => (
          <MenuItem key={key} value={state.stateId}>
            {" "}
            {state.stateName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectOperator: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { operatorId: 0, operator: "" };
  const [Operator, setOperator] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/Operator/getAllOperator", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setOperator(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth className="main-form-2"  >
      <InputLabel id="State-Select-Label">Operator</InputLabel>
      <Select {...field} label="Operator" labelId="State-Select-Label" IconComponent={ExpandMoreIcon} value={field.value || ""}>
        {Operator.map((state, key) => (
          <MenuItem key={key} value={state.operatorId}>

            {state.operator}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectCustomerPartnerStatus: React.FC<
  FieldAttributes<SelectProps>
> = ({ variant, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { statusId: 0, status: "" };
  const [States, setStates] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/PartnersStatus/getAllPartners", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setStates(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>
      <InputLabel id="State-Select-Label">Status</InputLabel>
      <Select {...field} labelId="State-Select-Label" label="State">
        {States.map((state, key) => (
          <MenuItem key={key} value={state.statusId}>
            {" "}
            {state.status}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectInsuranceCompany: React.FC<
  FieldAttributes<SelectProps>
> = ({ variant, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { insuranceCompanyId: 0, insuranceCompanyName: "" };
  const [InsuranceCompany, setInsuranceCompany] = useState([initialdata]);

  const fetchData = () => {
    fetch(
      config.url.API_URL + "/InsuranceCompany/getInsuranceCompanyByPartnerId",
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setInsuranceCompany(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth sx={{ m: 1 }}>
      <InputLabel id="State-Select-Label">Carrier</InputLabel>
      <Select {...field} label="Carrier">
        {InsuranceCompany.map((insuranceCompany, key) => (
          <MenuItem key={key} value={insuranceCompany.insuranceCompanyId}>
            {" "}
            {insuranceCompany.insuranceCompanyName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectMasterPolicyType: React.FC<
  FieldAttributes<SelectProps>
> = ({ variant, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { masterPolicyTypeId: 0, policyTypeName: "" };
  const [MasterPolicyType, setMasterPolicyType] = useState([initialdata]);

  const fetchData = () => {
    fetch(
      config.url.API_URL + "/MasterPolicyType/getMasterPolicyTypeByPartnerId",
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setMasterPolicyType(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth sx={{ m: 1 }}>
      <InputLabel id="State-Select-Label">Coverage Type</InputLabel>
      <Select {...field} label="Coverage Type">
        {MasterPolicyType.map((masterPolicyType, key) => (
          <MenuItem key={key} value={masterPolicyType.masterPolicyTypeId}>
            {" "}
            {masterPolicyType.policyTypeName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectLineOfBusiness: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { id: 0, businessCode: "" };
  const [LineOfBusiness, setLineOfBusiness] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/LineOfBusiness/getLineOBusinessForSelect", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        if (responseJson.data != null) {
          setLineOfBusiness(responseJson.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>

      {/* <InputLabel id="State-Select-Label">Line Of Business</InputLabel> */}
      <Select {...field} label={null} IconComponent={ExpandMoreIcon} className="border-round custom-menu-item border-round main-form-2">
        {LineOfBusiness.map((LineOfBusiness, key) => (
          <MenuItem className="custom-menu-item" key={key} value={LineOfBusiness.id}>
            {LineOfBusiness.businessCode}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FMSelectTransactionType: React.FC<
  FieldAttributes<SelectProps>
> = ({ variant, ...props }) => {
  interface transactiontypemodel {
    transactionId: number, transactionTypeName: string
  }
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const [TransactionType, setTransactionType] = useState<transactiontypemodel[] | undefined>();

  const fetchData = () => {
    fetch(config.url.API_URL + "/CustomerPolicy/GetTransactionTypeList", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setTransactionType(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth sx={{}}>
      <InputLabel id="TranssactionType-simple-select-label" className="border-round ">Select Transaction Type</InputLabel>
      <Select {...field}
        label={"Select Transaction Type"}
        labelId="TranssactionType-simple-select-label"
        id="demo-simple-select"
        className="border-round"
        value={undefined}
        IconComponent={ExpandMoreIcon}


      >

        {TransactionType?.map((TransactionType, key) => (
          <MenuItem key={key} value={TransactionType.transactionId}>
            {TransactionType.transactionTypeName}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectCarrier: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { insuranceCompanyId: 0, name: "" };
  const [Carrier, setCarrier] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/Carrier/getCarrierSelect", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response
        if (responseJson.data != null) {
          console.log(responseJson.data);
          setCarrier(responseJson.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const handleChange = (event: SelectChangeEvent) => {
  //     console.log(event.target.value);
  //  };

  return (
    <React.Fragment>
      <FormControl error={!!errorText} fullWidth sx={{}}>
        <InputLabel id="State-Select-Label">Carrier</InputLabel>
        <Select {...field} labelId="State-Select-Label" label="Carrier">
          {Carrier.map((carrier, key) => (
            <MenuItem key={key} value={carrier.insuranceCompanyId}>
              {" "}
              {carrier.name}{" "}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </React.Fragment>
  );
};

export const FMSelectDocumentType: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { documentTypeId: 0, documentName: "" };
  const [DocumentType, setDocumentType] = useState([initialdata]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/CustomerPolicy/GetDocumentTypeList", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setDocumentType(responseJson.data);
        console.log("documentdata", responseJson);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth sx={{ m: 1 }} className="main-form-2"  >
      <InputLabel id="State-Select-Label">Document Type</InputLabel>
      <Select
        {...field}
        label="Document Type"
        IconComponent={ExpandMoreIcon}
        value={field.value || ""}
      >
        {DocumentType.map((DocumentType, key) => (
          <MenuItem key={key} value={DocumentType.documentTypeId}>
            {" "}
            {DocumentType.documentName}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectButlerModel: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { modelId: 0, name: "" };
  const [ButlerModelData, setButlerModelData] = useState([initialdata]);
  interface butlerList {
    modelId: number;
    name: string;
  }
  const fetchData = () => {
    fetch(config.url.API_URL + "/ButlerModel/getAllButlerModels", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setButlerModelData(responseJson.data);
        console.log("butlerdata", responseJson);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [selectedOption, setSelectedOption] = useState<butlerList | null>(null);
  const handleOptionChange = (
    event: React.ChangeEvent<{}>,
    newValue: butlerList | null
  ) => {
    setSelectedOption(newValue);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth sx={{ m: 1 }}>
      <InputLabel id="State-Select-Label"></InputLabel>
      {/* <Select {...field} label="Butler Model">

                {ButlerModelData.map((ButlerModelData, key) =>
                    <MenuItem key={key} value={ButlerModelData.modelId}> {ButlerModelData.name} </MenuItem>


                )}

            </Select> */}
      <Autocomplete
        options={ButlerModelData}
        getOptionLabel={(option) => option.name}
        value={selectedOption}
        onChange={handleOptionChange}
        popupIcon={<ExpandMoreIcon />}
        renderInput={(params) => (
          <TextField {...params} label="Select Model" variant="outlined" />
        )}
      />

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectRolesCustom: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;
  const initialdata = { roleId: 0, roleName: "" };
  const [Roles, setRoles] = useState([initialdata]);
  const [selectedOptions, setSelectedOption] = React.useState<string | []>([]);

  const fetchData = () => {
    fetch(config.url.API_URL + "/Users/getAllRoles", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setRoles(responseJson.data);
        console.log("userData", responseJson);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth sx={{ m: 1 }}>
      <InputLabel id="State-Select-Label">Roles</InputLabel>
      <Select
        {...field}
        label="Roles"
        multiple
        value={selectedOptions}
        onChange={(e) => {
          setSelectedOption(e.target.value);
        }}
        input={<OutlinedInput label="Multiple Select" />}
      >
        {Roles.map((Role, key) => (
          <MenuItem key={key} value={Role.roleId}>
            {" "}
            {Role.roleName}{" "}
          </MenuItem>
        ))}
      </Select>

      {/* <Multiselect

                displayValue="key"
                id='rolesArray'
                placeholder='Select Roles'
                options={Roles}
                onSelect={(selectedList, selectedItem) => {
                    setSelectedOption(selectedList);
                    console.log(selectedList);
                }}
                onRemove={(selectedList, selectedItem) => {
                    setSelectedOption(selectedList);
                    console.log(selectedList);
                }}
                selectedValues={selectedOptions} /> */}

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export const FMSelectTaxAndFees: React.FC<FieldAttributes<SelectProps>> = ({
  variant,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { user } = useAuth();
  const token = user?.token;

  const [TaxandFees, setTaxandFees] = useState([""]);
  const params = useParams();

  const fetchData = () => {
    fetch(config.url.API_URL + `/TaxesAndFees/GetTaxName?type=${params.type}`, {
      // fetch(config.url.API_URL + `/TaxesAndFees/GetAllTaxandFeeName`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        if (responseJson.data != null) {
          setTaxandFees(responseJson.data);
        }

        console.log("TaxandFees", responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl error={!!errorText} fullWidth>
      {/* <InputLabel id="State-Select-Label">TaxandFees</InputLabel> */}
      <Select {...field} labelId="State-Select-Label" label={null}
        className="border-round custom-menu-item"
        IconComponent={ExpandMoreIcon}
        displayEmpty>
        <MenuItem key={0} value={""}>
          Select Tax
        </MenuItem>
        {TaxandFees.map((taxandfees, key) => (
          <MenuItem key={key} value={taxandfees}>
            {" "}
            {taxandfees}{" "}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export interface OptionType {
  value: string
  label?: string
}
interface SelectControlProp {
  value: string
  options: OptionType[]
  onChange: (val: string) => void
}
export const SelectControl: React.FC<SelectControlProp> = ({
  value: curValue,
  options,
  onChange,
}) => {
  const initFunc = useCallback(() => {
    if (!options || options.length <= 0 || curValue) return
    const update = options[0].value
    onChange(update)
  }, [options])
  useEffect(() => {
    initFunc()
  }, [])

  const handleChange = (event: SelectChangeEvent) => {
    const update = event.target.value
    onChange(update)
  }

  return (
    <FormControl fullWidth>
      <Select
        label={null}
        className="border-round custom-menu-item"
        IconComponent={ExpandMoreIcon}
        displayEmpty
        value={curValue}
        onChange={handleChange}
      >
        {options.map((item, key) => {
          return <MenuItem key={key} value={item.value}>
            {item.label}
          </MenuItem>
        })}
      </Select>
    </FormControl>
  );
};
