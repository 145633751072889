import * as XLSX from 'xlsx'

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const writeXlsxFile = async (data: any[][], filename: string = 'reporting.xlsx') => {
  console.log('===writeXlsxFile===', JSON.stringify(data));
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');
  worksheet.addRows(data);

  // Headers that need to be formatted as currency
  const currencyHeaders = ['TaxAmount', 'GrossPremium', 'TaxAmt', 'NonAdmittedPremium', 'NonTaxableFees', 'TaxableFees', 'TaxBalance'];
  const dateHeaders = ['FilingDueDate', 'InvoiceDate', 'PolicyEffectiveDate', 'PolicyExpDate', 'TransactionEffDate', 'InvDate', 'PolicyEffDate', 'PolicyExpDate', 'DateInvoiceAdded',
    'DateFiled', 'PaymentNoticeDate', 'DiligentSearchProcessDate', 'DiligentSearchToGMAC', 'FirstRequest', 'SecondRequest', 'ThirdRequest', 'Suspended', 'DateComplete'];

  // Apply styling to header row
  const headerStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF106C8C' }, // Header background color
    },
    font: {
      bold: true,
      color: { argb: 'FFFFFFFF' }, // White text color
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'left', // Left align header text
    },
  };

  const headerRow = worksheet.getRow(1);
  headerRow.eachCell({ includeEmpty: true }, (cell) => {
    Object.assign(cell, headerStyle);
  });

  worksheet.addConditionalFormatting({
    ref: `A2:${worksheet.getColumn(data[0].length).letter}${worksheet.rowCount}`, // Adjust based on your data
    rules: [
      {
        type: 'expression',
        formulae: ['MOD(ROW(),2)=0'], // Use 'formulae' array for the conditional formatting rule
        style: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: 'FFADD8E6' }, // Light blue color for even rows
          },
        },
        priority: 1, // Added 'priority' to fix the TypeScript error
      },
    ],
  });

  // Apply alternating row colors, alignment, and format currency
  const columnCount = data[0].length; // Number of headers
  for (let i = 2; i <= worksheet.rowCount; i++) {
    const row = worksheet.getRow(i);

    // Apply formatting only up to the number of header columns
    for (let j = 1; j <= columnCount; j++) {
      const cell = row.getCell(j);

      const headerName = data[0][j - 1] as string; // Get the header name based on the column index

      if (currencyHeaders.includes(headerName)) {
        // Ensure cell.value is a string, and handle null/undefined cases
        const cellValue = cell.value ? cell.value.toString() : '0';

        // Check for negative values in parentheses format, e.g., ($697.00)
        let numericValue: number;
        if (cellValue.includes('(') && cellValue.includes(')')) {
          // It's a negative value in parentheses, remove parentheses and set the value as negative
          const cleanValue = cellValue.replace(/[($)]/g, ''); // Remove '(' and ')'
          numericValue = parseFloat(cleanValue.replace(/[$,]/g, '')) * -1; // Parse as negative
        } else {
          // Positive or regular value
          numericValue = parseFloat(cellValue.replace(/[$,]/g, '') || '0'); // Remove '$' and ',' and parse as float
        }

        // Assign the numeric value back to the cell
        cell.value = numericValue;

        // Apply currency formatting
        cell.numFmt = '"$"#,##0.00;\-"$"#,##0.00';
      }

      if (dateHeaders.includes(headerName)) {
        // Apply date formatting
        cell.numFmt = 'mm/dd/yyyy';
      }

      cell.border = {
        top: { style: 'thin', color: { argb: 'FFADD8E6' } }, // Blue border
        left: { style: 'thin', color: { argb: 'FFADD8E6' } },
        bottom: { style: 'thin', color: { argb: 'FFADD8E6' } },
        right: { style: 'thin', color: { argb: 'FFADD8E6' } },
      };
    }
  }

  // Apply autofilter to each column
  worksheet.autoFilter = { from: { row: 1, column: 1 }, to: { row: 1, column: columnCount } };

  // Set column widths
  worksheet.columns = Array(columnCount).fill({ width: 20 });

  // Freeze header row
  worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  saveAs(blob, filename);
  const file = new File([blob], filename, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  return file
  console.timeEnd('===writeXlsxFile===')
};

// const writeXlsxFile = async (data: any[][], filename: string = 'reporting.xlsx') => {

//   const workbook = new ExcelJS.Workbook();
//   const worksheet = workbook.addWorksheet('Sheet1');
//   worksheet.addRows(data);

//   // Apply styling to header row
//   const headerRow = worksheet.getRow(1);
//   headerRow.eachCell({ includeEmpty: true }, (cell) => {
//     cell.fill = {
//       type: 'pattern',
//       pattern: 'solid',
//       fgColor: { argb: 'FF106C8C' } // Header background color
//     };
//     cell.font = {
//       bold: true,
//       color: { argb: 'FFFFFFFF' } // White text color
//     };
//     cell.alignment = {
//       vertical: 'middle',
//       horizontal: 'left' // Left align header text
//     };
//   });
//   // Apply alternating row colors and alignment
//   for (let i = 2; i <= worksheet.rowCount; i++) {
//     const row = worksheet.getRow(i);
//     const color = i % 2 === 0 ? 'FFADD8E6' : ''; // Light blue color for even rows
//     row.fill = {
//       type: 'pattern',
//       pattern: 'solid',
//       fgColor: { argb: color }

//     }
//     row.eachCell({ includeEmpty: true }, (cell) => {

//       cell.border = {
//         top: { style: 'thin', color: { argb: 'FFADD8E6' } }, // Blue border
//         left: { style: 'thin', color: { argb: 'FFADD8E6' } },
//         bottom: { style: 'thin', color: { argb: 'FFADD8E6' } },
//         right: { style: 'thin', color: { argb: 'FFADD8E6' } }
//       };
//     });
//   }

//   // Apply autofilter to each column
//   const columnCount = data[0].length;
//   worksheet.autoFilter = { from: { row: 1, column: 1 }, to: { row: 1, column: columnCount } };


//   for (let i = 1; i <= columnCount; i++) {
//     const column = worksheet.getColumn(i);  
//     column.width = 20;  
//   }
//   worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];

//   const buffer = await workbook.xlsx.writeBuffer();
//   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

//   saveAs(blob, filename); 
// };

// const writeXlsxFile = async (data: any, filename: string = 'reporting.xlsx') => {
//   const workbook = XLSX.utils.book_new()
//   const worksheet = XLSX.utils.aoa_to_sheet(data)
//   const headerRange = XLSX.utils.decode_range(worksheet['!ref']!);
//   worksheet['!autofilter'] = { ref: XLSX.utils.encode_range({
//     s: { r: 0, c: 0 },
//     e: { r: 0, c: headerRange.e.c }
//   })};
//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
//   await XLSX.writeFile(workbook, filename)
// }

const readXlsxFile = async (file: File, headerNames?: string[]) => {
  try {
    const data = await parseXlsxFile(file, headerNames)
    console.log('success reading xlsx file')
    return data as any
  } catch (error) {
    console.error('failed to read xlsx file')
  }
}
const parseXlsxFile = async (file: File, headerNames?: string[]) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (_e) => {
      try {
        const buffArr = _e.target?.result as ArrayBuffer
        const workbook = XLSX.read(buffArr, { type: 'binary' })

        const sheetsData: any = {}
        workbook.SheetNames.forEach(sheetName => {
          // const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]
          let headerRow = 0;
          for (let i = 1; i <= 20; i++) {
            const cell = sheet[XLSX.utils.encode_cell({ r: i - 1, c: 0 })]

            if (cell && typeof cell.v === 'string') {
              headerRow = i - 1;
              break;
            }
          }
          const jsonData: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1, range: headerRow })
          // Set the first array item as Row number that matches the Excel file's row number.
          const jsonDataRows = jsonData.map((item, index) => ([headerRow + index + 1, ...item]))

          // Find header row index
          const lenList = jsonDataRows.map((d) => (d as any[]).length)
          const headerIndex = indexOfHeader(lenList)
          if (headerIndex > 0) {
            jsonDataRows.splice(0, headerIndex)
          }
          sheetsData[sheetName] = jsonDataRows
        })
        resolve(sheetsData)
      } catch (_error) {
        reject(_error)
      }
    }
    reader.onerror = (_error) => {
      reject(_error)
    }
    const fileExtension = file.name.split('.').pop()
    if (fileExtension === 'csv') {
      reader.readAsText(file)
    } else {
      reader.readAsArrayBuffer(file)
    }
  })
}

const convertDateFromXlsx = (val: number) => {
  const excelBaseDate = new Date(1899, 11, 30); // Excel's base date for date serial
  const ms = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const offset = val * ms; // Calculate the offset in milliseconds
  const parsedDate = new Date(excelBaseDate.getTime() + offset); // Add the offset to the base date
  return parsedDate; // Return the parsed date
};

const convertDateToXlsx = (val: Date) => {
  const excelBaseDate = new Date(1899, 11, 30)
  const ms = 24 * 60 * 60 * 1000
  const offset = val.getTime() - excelBaseDate.getTime()
  const parsedDate = Math.floor(offset / ms)
  return parsedDate
}

const compactString = (val: string | undefined) => {
  return val?.toLowerCase().replaceAll(' ', '')
}

const isNanOrZero = (val: string | undefined) => {
  return isNaN(+(val ?? '')) || (+(val ?? 0) === 0)
}
const isEmptyOrUndefined = (str: string | undefined) => {
  return str === "" || str === null || str === undefined
}
// const isInvoiceNumber = (str: string | undefined) => {
//   if (!str) return false
//   return /\d/.test(str)
// }

const isInvoiceNumber = (str: string | undefined) => {
  if (!str) return false;
  return /^[A-Za-z0-9]+$/.test(str);
}

const indexOfHeader = (arr: number[]) => {
  if (arr.length === 0) {
    return 0
  }
  let max = arr[0]
  let maxIndex = 0
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max + 2) {
      maxIndex = i
      max = arr[i]
    }
  }
  return maxIndex
}

const toCurrency = (val: any) => {
  return (val === "" || val === undefined) ? undefined : (+(+val).toFixed(2).replace(/\.?0+$/, '')).toLocaleString()
}

const isUserRoleInclude = (userRoles: string[] | undefined, arr: string[] | undefined) => {
  if (!userRoles || !Array.isArray(arr)) return false
  const roles = userRoles.map(item => compactString(item))
  const arrCompact = arr.map(item => compactString(item))
  return roles.some((role) => arrCompact.includes(role))
}

export {
  writeXlsxFile,
  readXlsxFile,
  convertDateFromXlsx,
  convertDateToXlsx,
  compactString,
  isNanOrZero,
  isEmptyOrUndefined,
  isInvoiceNumber,
  toCurrency,
  isUserRoleInclude,
}