import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import { Autocomplete, Input, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, LinearProgress, Link, IconButton, MenuItem, Select, SelectChangeEvent, SelectProps, TextField, } from '@mui/material';
import Button from '@mui/material/Button';
import { FieldAttributes, Form, Formik, useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../Constants';
import useAuth from "../../context/AuthProvider";
import { PartnerAgencyDetailsModel } from '../../models/PartnerAgencyDetails';
import { PartnersModel, PartnersModelVS } from '../../models/PartnersModel';
import { InsuranceCompanyCheckBox } from '../Elements/InsuranceCompanyCheckBox';
import { StateCheckBoxAgencyDetails } from '../Elements/StateCheckBoxAgencyDetails';
import { FMTextField } from '../Elements/input';
import { LoggedInUserInfo } from '../Elements/loggedInUserInfo';
import { FMSelectActiveInactive, FMSelectPartnerIntegrationType, FMSelectPartnerPaymentType, FMSelectPartnerType, FMSelectState, FMSelectEscalation, FMSelectMultiState } from '../Elements/select';
import { RightNav } from '../Nav/RightNav';
import { AddEditPartnerAgencyDetails } from './AddEditPartnerAgencyDetails';
import { useDropzone } from 'react-dropzone';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";
import { RiExpandUpDownLine } from "react-icons/ri";
import { fontSize } from '@mui/system';
import { Partners } from '../../Pages/Partners';
import { PartnerTaxesAndFees } from '../../Pages/PartnerTaxandFees';
import { number } from 'yup';
import { PartnerTaxesAndFeesList } from '../Lists/PartnerTaxandFeeList';
import { LicenseMatrix } from '../Lists/LicenseMatrix';
import { partnertaxesandfeesModel } from '../../models/PartnerTaxandFeeModel';

// interface Iprops {
//     CustomerPartnersCL: PartnersModel,
//     handleClose: () => void,
//     Open: boolean,
//     handleSubmit: () => void
//     initValues: PartnersModel
//     selectedStates: stateOptions[]
// }
var formData = new FormData();
interface FilingContactInformation {
    partnerFilingContactInformationId: number
    isAutoNotification?: boolean
    contactName: string
    contactEmail: string
    contactPhone: string
}

interface AcountingContactInformation {
    partnerAccountingInformationId: number
    isAutoNotification?: boolean
    contactName: string
    contactEmail: string
    contactPhone: string
}

interface LicenseesContactInformation {
    partnerLincenseeInformationId: number;
    partnersId: number;
    cancellationDate: string;
    entity: boolean;
    fein: string;
    licenseNumber: string;
    licenseState: string;
    licensee: string;
    mainLicensee: boolean;
    npn: string;
    title: string;
}

interface SLAAdminContactInformation {
    partnerSLaAdminContactInformationId: number
    isAutoNotification?: boolean
    contactName: string
    contactEmail: string
    contactPhone: string
}

interface EscalationContactInformation {
    partnerEscalationInformationId: number
    isAutoNotification?: boolean
    contactName: string
    contactEmail: string
    contactPhone: string
}

interface DRLPContactInformation {
    partnerDRLPContactInformationId: number
    isAutoNotification?: boolean
    contactName: string
    contactEmail: string
    contactPhone: string
}

export interface ReqFile {
    documentName?: string,

}

export interface stateOptions {
    stateId: number,
    stateName: string,

}
export interface AgencyStateOptions {
    stateId: number,
    stateName: string,
    agencyLicense: string,

}


const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#41729F',
    borderStyle: 'dashed',


    backgroundColor: '#fafafa',
    // color: '#bdbdbd',
    color: '#41729F',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};



export interface PartnerInsuranceCompany {
    insuranceCompanyId: number,
    name: string,
}

export const AddEditPartners = () => {
    const initialValues: PartnersModel = {
        partnersId: 0,
        customerName: '',
        partnerTypeId: 0,
        streetAddress: '',
        suitOrUnit: '',
        city: '',
        state: 0,
        zipCode: '',
        agencyLicense: '',
        taxId: '',
        numberOfStates: '',
        filingContactName: '',
        filingContactEmail: '',
        filingContactPhone: "",
        accountingContactName: '',
        accountingContactEmail: '',
        accountingContactPhone: "",
        licenseeContactName: '',
        licenseeContactEmail: '',
        licenseeContactPhone: "",
        slaAdminContactName: '',
        slaAdminContactEmail: '',
        slaAdminContactPhone: "",
        escalationContactName: '',
        escalationContactEmail: '',
        escalationContactPhone: "",
        drlpContactName: '',
        drlpContactEmail: '',
        drlpContactPhone: "",


        paymentPartnerType: 0,
        paymentStreetAddress: '',
        paymentSuitOrUnit: '',
        paymentCity: '',

        paymentZipCode: 0,
        paymentNaic: '',
        paymentTaxId: '',
        partnerIntegrationType: 0,
        agencyPolicyManagementSystem: '',
        userName: '',
        password: '',
        modifiedBy: 0,
        paymentType: true,
        paymentState: 0,
        createdBy: 0,
        status: false,
        latestTransactionNumber: '',
        previousCredit: 0,
        originalAmount: 0,
        carryForward: 0,
        generateSL1: false,
        signature: '',
        apiToken: '',
        isDiligentSearch: false



    }

    const [InitialData, setInitialData] = useState<PartnersModel>(initialValues);


    function GenericStyledDropZone(props: any) {


        const {
            getRootProps,
            getInputProps,
            acceptedFiles,
            isFocused,
            isDragAccept,
            isDragReject
        } = useDropzone({
            accept: { 'image/*': [] }, onDrop:
                (files) => {


                    console.log("initgenrateSL1 ", InitialData.generateSL1);
                    setInitialData({ ...InitialData, signature: URL.createObjectURL(files[0]) });
                    formData.delete("signaturedocument");
                    formData.append("signaturedocument", files[0]);

                    // files.map((file) => {
                    //     formData.append("props.doc.documentName", file);
                    // });

                }
        });

        const style = useMemo(() => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }), [
            isFocused,
            isDragAccept,
            isDragReject
        ]);


        const files = acceptedFiles.map
            (
                file =>
                (
                    <React.Fragment>
                        <FilePresentIcon fontSize='inherit' />
                        <li key={file.name}>
                            {file.name} - {(file.size / 1024).toFixed(2)} kb
                        </li>
                    </React.Fragment>
                )

            );

        return (
            <React.Fragment>
                <div className="card-body col-lg-12">

                    <div {...getRootProps({ style })}>

                        <input {...getInputProps()} name={"Signature document"} />
                        <p>Drop Signature here if you want to update it  <span style={{ color: "red" }}></span> </p>

                    </div>
                    <aside>
                        <ul>{files}</ul>
                    </aside>
                </div>

            </React.Fragment>
        );
    }
    const navigate = useNavigate();
    // const initialdata = { stateId: "", stateName: "" };
    const params = useParams();

    const [selectedStates, setselectedStates] = useState<AgencyStateOptions[]>([]);


    const { user } = useAuth();
    var isUserAdmin=user?.userRoles.split(",").includes("Super Admin");
    const token = user?.token;
    // const navigate1 = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const [DiligentSearch, setDiligentSearch] = useState<boolean>(false)
    function handleDiligentSearch() {
        var value = DiligentSearch == true ? false : DiligentSearch === null || DiligentSearch === undefined || DiligentSearch == false ? true : false
        setDiligentSearch(value);
    }


    const [partnerAgencyDetailList, setPartnerAgencyDetailList] = useState<PartnerAgencyDetailsModel[]>([]);
    let iniAgencyValues: PartnerAgencyDetailsModel = {
        partnerAgencyDetailsId: 0,
        firstName: "",
        lastName: "",
        licenseNumber: "",
        slBrokerNumber: "",
        agencyLicenseNumber: "",
        latestTransactionNumber: "",
        nationalProducerNumber: "",
        previousCredit: 0,
        carryForward: 0,
        originalAmount: 0,
        individualNPN: "",
        dateLicensed: undefined,
    }
    const [PartnerAgencyData, setPartnerAgencyData] = useState<PartnerAgencyDetailsModel>(iniAgencyValues);


    const [loading, setloading] = useState(false);

    const [selectedPartnerInsuranceCompany, setselectedPartnerInsuranceCompany] = useState<PartnerInsuranceCompany[]>([]);
    const [PartnerInsuranceCompany, setPartnerInsuranceCompany] = useState<PartnerInsuranceCompany[]>([])

    const [StateName, setStateName] = useState<string>("")
    var initAcountingContactInformation: AcountingContactInformation = {
        partnerAccountingInformationId: 0,
        isAutoNotification: false,
        contactName: '',
        contactEmail: '',
        contactPhone: ''
    };

    var initFilingContactInformation: FilingContactInformation = {
        partnerFilingContactInformationId: 0,
        isAutoNotification: false,
        contactName: '',
        contactEmail: '',
        contactPhone: ''
    };

    var initLicenseeContactInformation: LicenseesContactInformation = {
        partnerLincenseeInformationId: 0,
        partnersId: 0,
        cancellationDate: '',
        entity: false,
        fein: '',
        licenseNumber: '',
        licenseState: '',
        licensee: '',
        mainLicensee: false,
        npn: '',
        title: ''
    };

    var initSLAAdminContactInformation: SLAAdminContactInformation = {
        partnerSLaAdminContactInformationId: 0,
        isAutoNotification: false,
        contactName: '',
        contactEmail: '',
        contactPhone: ''
    };

    var initEscalationContactInformation: EscalationContactInformation = {
        partnerEscalationInformationId: 0,
        isAutoNotification: false,
        contactName: '',
        contactEmail: '',
        contactPhone: ''
    };

    var initDRLPContactInformation: DRLPContactInformation = {
        partnerDRLPContactInformationId: 0,
        isAutoNotification: false,
        contactName: '',
        contactEmail: '',
        contactPhone: ''
    };

    const [filingContactArry, setFilingContactArry] = useState([initFilingContactInformation]);
    const [acountingContactArry, setAcountingContactArry] = useState([initAcountingContactInformation]);
    const [slaAdminContactArry, setSLAAdminContactArry] = useState([initSLAAdminContactInformation]);
    const [escalationContactArry, setEscalationContactArry] = useState([initEscalationContactInformation]);
    const [drlpContactArry, setDRLPContactArry] = useState([initDRLPContactInformation]);
    // const [licenseesContactArry, setLicenseesContactArry] = useState([initLicenseeContactInformation]);
    const [licenseesContactArry, setLicenseesContactArry] = useState<LicenseesContactInformation[]>([]);

    const [isLicenseesInfoLoading, setisLicenseesInfoLoading] = useState(true);

    const [PartnerId1, setPartnerId] = useState<string>('');


    const [Open, setOpen] = React.useState(false);


    const fetchPartnerAgencyData = (stateId: string) => {

        setloading(true);
        fetch(config.url.API_URL + `/PartnerAgencyDetails/getById?partnersId=${params.partnersId}&&stateId=${stateId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                setloading(false);

                if (responseJson.data !== null) {

                    const finalData: PartnerAgencyDetailsModel = {
                        partnerAgencyDetailsId: responseJson.data.partnerAgencyDetailsId,
                        agencyLicenseNumber: responseJson.data?.agencyLicenseNumber ?? "",
                        nationalProducerNumber: responseJson.data?.nationalProducerNumber ?? "",
                        latestTransactionNumber: InitialData.latestTransactionNumber,
                        firstName: responseJson.data?.firstName ?? "",
                        lastName: responseJson.data?.lastName ?? "",
                        licenseNumber: responseJson.data?.licenseNumber ?? "",
                        partnersId: responseJson.data.partnersId,
                        slBrokerNumber: responseJson.data?.slBrokerNumber ?? "",
                        stateID: responseJson.data?.stateID,
                        taxId: responseJson.data?.taxId ?? "",
                        originalAmount: InitialData.originalAmount,
                        carryForward: InitialData.carryForward,
                        previousCredit: InitialData.previousCredit,
                        individualNPN: responseJson.data?.individualNPN,
                        dateLicensed: responseJson.data?.dateLicensed ? new Date(responseJson.data?.dateLicensed).toISOString().split('T')[0] : undefined,
                    }
                    console.log('get agency finalData', { finalData })
                    setPartnerAgencyData(finalData);

                } else {

                    iniAgencyValues.stateID = Number(stateId);

                    iniAgencyValues.partnersId = Number(params.partnersId);


                    setPartnerAgencyData(iniAgencyValues);
                }

            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }


    function addselectedStates(stateId: string, licenseNumber?: string) {

        let crrOption: AgencyStateOptions =
        {
            stateId: Number(stateId),
            stateName: '',
            agencyLicense: licenseNumber ?? '',

        }
        // var selStates = selectedStates.filter(e=>e.stateId === Number( stateId));
        setselectedStates(current => [...current.filter(e => e.stateId !== Number(stateId)), crrOption]);

    }
    function RemoveSelected(stateId: string) {

        setPartnerAgencyDetailList(
            partnerAgencyDetailList.filter(obj =>
                obj.stateID !== Number(stateId),
            )
        );

        setselectedStates(
            selectedStates.filter(obj =>
                obj.stateId !== Number(stateId),

            )

        )

    }

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let statename =
            States.filter(e =>
                e.stateId === Number(event.target.name)
            )[0].stateName ?? "";

        setStateName(statename);



        if (!event.target.checked) {

            fetchPartnerAgencyData(event.target.name);
            setOpen(true);

        } else {
            iniAgencyValues.stateID = Number(event.target.name);

            iniAgencyValues.partnersId = Number(params.partnersId);
            setPartnerAgencyData(iniAgencyValues);
            setOpen(true);

        }


    };

    const handleInsuranceCompanyCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {


            let crrOption: PartnerInsuranceCompany = {
                insuranceCompanyId: Number(event.target.name),
                name: ''
            }
            setselectedPartnerInsuranceCompany(current => [...current, crrOption]);

        } else {

            setselectedPartnerInsuranceCompany(
                selectedPartnerInsuranceCompany.filter(obj =>
                    obj.insuranceCompanyId !== Number(event.target.name)
                )
            )
        }

    };

    const [isContactInfoLoading, setisContactInfoLoading] = useState(true);
    const [isaccountInfoLoading, setisaccountInfoLoading] = useState(true);

    const [isSLAAdminInfoLoading, setisSLAAdminInfoLoading] = useState(true);
    const [isEscalationInfoLoading, setisEscalationInfoLoading] = useState(true);
    const [isDRLPInfoLoading, setisDRLPInfoLoading] = useState(true);



    const fetchStates = () => {

        fetch(config.url.API_URL + '/States/getStates',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response

                setStates(responseJson.data);


            }).then(() => {
                fetch(config.url.API_URL + `/Partners/getPartnerStates?partnersId=${params.partnersId}`,
                    {
                        method: "GET",
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        })
                    }).then((response) => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((responseJson) => {
                        if (responseJson.data) {
                            setselectedStates(responseJson.data);
                        }


                        setloading(false);


                        // setSelectedOption(States.filter(x => partnerStates.map((item)=>(item.stateId))));
                    })
            }).catch((error) => {
                console.log(error)

            });


    }
    const fetchData = () => {

        if (params.partnersId !== '0') {

            fetch(config.url.API_URL + `/Partners/getPartnerById?partnersId=${params.partnersId}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {

                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response
                    if (responseJson.data != null) {

                        setInitialData(responseJson.data);
                        setDiligentSearch(responseJson.data.isDiligentSearch);
                        responseJson.data.generateSL1 == true ? setSelectedValue('true') : setSelectedValue('false');
                        fetchDataStates();

                    }


                }).catch((error) => {
                    console.log(error)

                });

        }


    }
    const fetchCustomerData = () => {
        let partnerid = '';
        if (params.partnersId !== "0") {
            partnerid = params.partnersId || '';
            fetch(config.url.API_URL + `/Partners/getcontactInformationById?partnerId=${params.partnersId}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response

                    if (responseJson.data != null) {
                        setFilingContactArry(responseJson.data);
                        setPartnerId(partnerid);
                        console.log('PartnerId set to:', partnerid);
                    }
                    setisContactInfoLoading(false);
                    console.log("contactData", responseJson)
                }).catch((error) => {

                    console.log(error)

                });
        } else {
            setisContactInfoLoading(false);
        }




    };

    const fetchAccountinfoData = () => {
        if (params.partnersId !== "0") {

            fetch(config.url.API_URL + `/Partners/getAccountInformationById?partnerId=${params.partnersId}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response

                    if (responseJson.data != null) {

                        setAcountingContactArry(responseJson.data);
                    }
                    setisaccountInfoLoading(false);
                }).catch((error) => {

                    console.log(error)

                });
        } else {
            setisaccountInfoLoading(false);
        }

    };

    const fetchLicensessData = () => {
        fetch(config.url.API_URL + `/Partners/getLicenseesContactInformationById?partnerId=${params.partnersId}`, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                if (responseJson.data != null) {
                    setLicenseesContactArry(responseJson.data);
                // } else {
                //     setLicenseesContactArry([initLicenseeContactInformation]);
                }
                setisLicenseesInfoLoading(false);
            }).catch((error) => {
                console.log(error);
                setisLicenseesInfoLoading(false);
            });
    };

    const fetchSLAAdminData = () => {
        if (params.partnersId !== "0") {
            fetch(config.url.API_URL + `/Partners/getSLAAdminContactInformationById?partnerId=${params.partnersId}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response

                    if (responseJson.data != null) {
                        setSLAAdminContactArry(responseJson.data);
                    }
                    setisSLAAdminInfoLoading(false);
                    console.log("contactData", responseJson)
                }).catch((error) => {

                    console.log(error)

                });
        } else {
            setisSLAAdminInfoLoading(false);
        }

    };

    const fetchEscalationData = () => {
        if (params.partnersId !== "0") {
            fetch(config.url.API_URL + `/Partners/getEscalationContactInformationById?partnerId=${params.partnersId}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response

                    if (responseJson.data != null) {
                        setEscalationContactArry(responseJson.data);
                    }
                    setisEscalationInfoLoading(false);
                    console.log("contactData", responseJson)
                }).catch((error) => {

                    console.log(error)

                });
        } else {
            setisEscalationInfoLoading(false);
        }

    };

    const fetchDRLPData = () => {
        if (params.partnersId !== "0") {
            fetch(config.url.API_URL + `/Partners/getDRLPcontactInformationById?partnerId=${params.partnersId}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response

                    if (responseJson.data != null) {
                        setDRLPContactArry(responseJson.data);
                    }
                    setisDRLPInfoLoading(false);
                    console.log("contactData", responseJson)
                }).catch((error) => {

                    console.log(error)

                });
        } else {
            setisDRLPInfoLoading(false);
        }




    };


    const fetchDataStates = () => {

        fetch(config.url.API_URL + '/States/getStates',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                if (responseJson.data != null) {

                    setStates(responseJson.data);
                    setSelected(States.find(x => x.stateId === responseJson.data.state) ?? null);
                }



            }).catch((error) => {

                console.log(error)

            });

    }


    const [GeneratedApi, setGeneratedApi] = useState<string>("")


    const handleButtonClick = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
        fetch(config.url.API_URL + "/TaxDetail/GenerateApiKey", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                if (responseJson.data != null) {
                    // InitialData.apiToken = responseJson.data;
                    setGeneratedApi(responseJson.data);
                    setFieldValue("apiToken", responseJson.data);

                }
                console.log("generatedApi key", responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });

        // handleInputChange(e);


    };

    const fetchInsuranceCompany = () => {
        fetch(config.url.API_URL + '/Carrier/getCarrierSelect',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response

                setPartnerInsuranceCompany(responseJson.data);
                 console.log("ins data",responseJson.data);

            }).then(() => {
                fetch(config.url.API_URL + `/Partners/getPartnerInsuranceCompany?partnerId=${params.partnersId}`,
                    {
                        method: "GET",
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        })
                    }).then((response) => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((responseJson) => {
                        // Do something with the response

                        if (responseJson.data != null) {
                            setselectedPartnerInsuranceCompany(responseJson.data);

                        }
                        // setSelectedOption(States.filter(x => partnerStates.map((item)=>(item.stateId))));
                    })
            }).catch((error) => {
                console.log(error)

            });

    }


    const [States, setStates] = useState<stateOptions[]>([]);
    const [selectedOption, setSelected] = useState<stateOptions | null>(null);
    const FMSelectStateCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const handleOptionChange = (event: React.ChangeEvent<{}>, newValue: stateOptions | null) => {
            setSelected(newValue);
        };
        useEffect(() => {
            // fetchData();

        }, [])
        return (
            <FormControl error={!!errorText} fullWidth  >
                {/* <InputLabel >State</InputLabel> */}
                <Select {...field} labelId="State-Select-Label" className="border-round custom-menu-item" IconComponent={ExpandMoreIcon}>
                    <MenuItem key={0} value={0}> Select State </MenuItem>
                    {States.map((state, key) =>
                        <MenuItem key={key} value={state.stateId}> {state.stateName} </MenuItem>

                    )}

                </Select>
                {/* <Autocomplete
                    options={States}
                    getOptionLabel={(option) => option.stateName}
                    value={selectedOption}
                    onChange={handleOptionChange}
                   
                    renderInput={(params) => (
                        <TextField {...params} label="" name="state" variant="outlined" />
                    )}
                /> */}
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }

    const [generateSL1, setGenerateSL1] = useState<boolean>();
    const [selectedValue, setSelectedValue] = useState<string | undefined>();
    const handleChange = (event: SelectChangeEvent) => {

        setSelectedValue(event.target.value);
        if (event.target.value === 'true') {
            setGenerateSL1(true);

        } else {
            setGenerateSL1(false);
        }
    };

    const FMSelectTrueFalseCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";

        return (
            <React.Fragment>
                <FormControl error={!!errorText} fullWidth sx={{ m: 0 }}>
                    {/* <FormHelperText className='formtexthelper-heading' > </FormHelperText> */}
                    <InputLabel id="Select-Label">Auto Generate SL1</InputLabel>
                    <Select {...field}
                        labelId="Select-Label"
                        label="Auto Generate SL1"
                        className="border-round custom-menu-item"
                        IconComponent={ExpandMoreIcon}
                        value={selectedValue || ""}
                        onChange={handleChange}>
                        <MenuItem value={'true'}>True</MenuItem>
                        <MenuItem value={'false'}>False</MenuItem>
                    </Select>

                </FormControl>
            </React.Fragment>
        );
    };

    const handleDeleteLicensee = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                
                fetch(config.url.API_URL + "/Partners/deleteLicensee?id=" + `${id}`, {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }),
                    method: "DELETE",
                })
                    .then((response) => {
                        if (response.ok) {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((res) => {
                        fetchLicensessData();
                        setOpen(false);
                    }).catch((error) => {
                        console.log(error)
                    });
            }
        });
    };

    const handleDeleteFilingContact = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(config.url.API_URL + "/Partners/deleteFilingContact?id=" + `${id}`, {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }),
                    method: "DELETE",
                })
                    .then((response) => {
                        if (response.ok) {
                            Swal.fire(
                                'Deleted!',
                                'This contact has been deleted.',
                                'success'
                            )
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((res) => {
                        fetchCustomerData();
                        setOpen(false);
                    }).catch((error) => {
                        console.log(error)
                    });
            }
        });
    };

    const handleDeleteAccountContact = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(config.url.API_URL + "/Partners/deleteAccountContact?id=" + `${id}`, {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }),
                    method: "DELETE",
                })
                    .then((response) => {
                        if (response.ok) {
                            Swal.fire(
                                'Deleted!',
                                'This contact has been deleted.',
                                'success'
                            )
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((res) => {
                        fetchAccountinfoData();
                        setOpen(false);
                    }).catch((error) => {
                        console.log(error)
                    });
            }
        });
    };

    const handleDeleteDRLPContact = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(config.url.API_URL + "/Partners/deleteDRLPContact?id=" + `${id}`, {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }),
                    method: "DELETE",
                })
                    .then((response) => {
                        if (response.ok) {
                            Swal.fire(
                                'Deleted!',
                                'This contact has been deleted.',
                                'success'
                            )
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((res) => {
                        fetchDRLPData();
                        setOpen(false);
                    }).catch((error) => {
                        console.log(error)
                    });
            }
        });
    };

    const handleDeleteSLAAdminContact = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(config.url.API_URL + "/Partners/deleteSLAADminContact?id=" + `${id}`, {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }),
                    method: "DELETE",
                })
                    .then((response) => {
                        if (response.ok) {
                            Swal.fire(
                                'Deleted!',
                                'This contact has been deleted.',
                                'success'
                            )
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((res) => {
                        fetchSLAAdminData();
                        setOpen(false);
                    }).catch((error) => {
                        console.log(error)
                    });
            }
        });
    };

    const handleDeleteEscalationContact = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(config.url.API_URL + "/Partners/deleteEscalationContactInformation?id=" + `${id}`, {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }),
                    method: "DELETE",
                })
                    .then((response) => {
                        if (response.ok) {
                            Swal.fire(
                                'Deleted!',
                                'This contact has been deleted.',
                                'success'
                            )
                            return response.json();
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((res) => {
                        fetchEscalationData();
                        setOpen(false);
                    }).catch((error) => {
                        console.log(error)
                    });
            }
        });
    };


    const initPartnerTaxFeeData: partnertaxesandfeesModel = {
        partnerTaxesandFeeId: 0,
        rate: 0
    }
    const [PartnerTaxesAndFee, setPartnerTaxesAndFee] = useState<partnertaxesandfeesModel[]>([])

    const fetchPartnerTaxAndFe = () => {
        
        fetch(config.url.API_URL + `/PartnerTaxandFee/GetPartnerTaxandFeeData?partnersId=${params.partnersId}`,
            {

                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                
                if (res.data !== null)
                    setPartnerTaxesAndFee(res.data)

                else
                    // setPartnerTaxesAndFee([initPartnerTaxFeeData]);
                    setloading(false);
                console.log(res.data);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });

    }


    useEffect(() => {
        fetchLicensessData();
        fetchCustomerData();
        fetchStates();
        fetchData();
        fetchInsuranceCompany();
        fetchAccountinfoData();
        fetchSLAAdminData();
        fetchEscalationData();
        fetchDRLPData();
        fetchPartnerTaxAndFe();


    }, [])


    return (

        <React.Fragment>

            <Formik initialValues={InitialData}
                enableReinitialize={true}
                validateOnChange={true}
                validationSchema={PartnersModelVS}

                onSubmit={(data, { setSubmitting }) => {
                    
                    data.partnerTaxAndFeesDTO = PartnerTaxesAndFee;
                    data.partnerAgencyDetails = partnerAgencyDetailList;

                    data.generateSL1 = selectedValue === 'true' ? true : false;

                    if (selectedValue === 'true' && data.signature === '') {

                        alert("Signature required");
                        return;
                    }
                    if (selectedValue === 'false') {
                        data.signature = '';
                    }


                    data.isDiligentSearch = DiligentSearch;
                    // data.apiToken = GeneratedApi;

                    console.log("FinalDataa", data);
                    if (partnerAgencyDetailList.length > 0) {

                        data.latestTransactionNumber = partnerAgencyDetailList.find(x => x.stateID === 30)?.latestTransactionNumber;
                        data.previousCredit = partnerAgencyDetailList.find(x => x.stateID === 14)?.previousCredit;
                        data.originalAmount = partnerAgencyDetailList.find(x => x.stateID === 14)?.originalAmount;
                        data.carryForward = partnerAgencyDetailList.find(x => x.stateID === 14)?.carryForward;



                        // alert("Select Agency Data in States");
                        // setSubmitting(false);
                        // return;
                    }



                    data.partnersId = Number(params.partnersId ?? 0);
                    if (data.partnersId !== 0) {
                        data.userName = "";
                        data.password = "";
                    }

                    data.numberOfStates = selectedStates.map(x => x.stateId).join(",");

                    setloading(true);

                    data.selectedInsuranceCompany = selectedPartnerInsuranceCompany.map(x => x.insuranceCompanyId).join(",");


                    var s = "";
                    var Accountdata = "";
                    var Licenseedata = "";
                    var SLAAdmindata = "";
                    var Escalationdata = "";
                    var DRLPdata = "";


                    filingContactArry?.forEach((item) => {
                        console.log("before concat", s);
                        s += (document.getElementById(`filingContactName_${item.partnerFilingContactInformationId}`) as HTMLInputElement)?.value + "#";
                        s += (document.getElementById(`filingContactEmail_${item.partnerFilingContactInformationId}`) as HTMLInputElement)?.value + "#";
                        s += (document.getElementById(`filingContactPhone_${item.partnerFilingContactInformationId}`) as HTMLInputElement)?.value + "#";
                        s += (document.getElementById(`filingContactCheckBox_${item.partnerFilingContactInformationId}`) as HTMLInputElement)?.checked ? 'true' : 'false';
                        s += "$";

                        console.log();

                    });

                    acountingContactArry?.forEach((item) => {
                        Accountdata += (document.getElementById(`accountingContactName_${item.partnerAccountingInformationId}`) as HTMLInputElement)?.value + "#";
                        Accountdata += (document.getElementById(`accountingContactEmail_${item.partnerAccountingInformationId}`) as HTMLInputElement)?.value + "#";
                        Accountdata += (document.getElementById(`accountingContactPhone_${item.partnerAccountingInformationId}`) as HTMLInputElement)?.value + "#";
                        Accountdata += (document.getElementById(`filingContactCheckBox_${item.partnerAccountingInformationId}`) as HTMLInputElement)?.checked ? 'true' : 'false';
                        Accountdata += "$";
                        console.log("respnse for accountinfo", Accountdata);

                    });

                    licenseesContactArry?.forEach((item, index) => {
                        Licenseedata += item.partnerLincenseeInformationId + "#"
                        Licenseedata += item.cancellationDate + "#"
                        Licenseedata += (item.entity ? 'true' : 'false') + "#"
                        Licenseedata += item.fein + "#"
                        Licenseedata += item.licenseNumber + "#"
                        Licenseedata += item.licenseState + "#"
                        Licenseedata += item.licensee + "#"
                        Licenseedata += (item.mainLicensee ? 'true' : 'false')  + "#"
                        Licenseedata += item.npn + "#"
                        Licenseedata += item.title ?? ''
 
                        Licenseedata += "$";

                        console.log("before concat", Licenseedata);

                    });

                    slaAdminContactArry?.forEach((item) => {
                        SLAAdmindata += (document.getElementById(`slaAdminContactName_${item.partnerSLaAdminContactInformationId}`) as HTMLInputElement)?.value + "#";
                        SLAAdmindata += (document.getElementById(`slaAdminContactEmail_${item.partnerSLaAdminContactInformationId}`) as HTMLInputElement)?.value + "#";
                        SLAAdmindata += (document.getElementById(`slaAdminContactPhone_${item.partnerSLaAdminContactInformationId}`) as HTMLInputElement)?.value + "#";
                        SLAAdmindata += (document.getElementById(`slaAdminContactCheckBox_${item.partnerSLaAdminContactInformationId}`) as HTMLInputElement)?.checked ? 'true' : 'false';
                        SLAAdmindata += "$";

                        console.log();

                        console.log("before concat", SLAAdmindata);

                    });

                    escalationContactArry?.forEach((item) => {
                        Escalationdata += (document.getElementById(`escalationContactName_${item.partnerEscalationInformationId}`) as HTMLInputElement)?.value + "#";
                        Escalationdata += (document.getElementById(`escalationContactEmail_${item.partnerEscalationInformationId}`) as HTMLInputElement)?.value + "#";
                        Escalationdata += (document.getElementById(`escalationContactPhone_${item.partnerEscalationInformationId}`) as HTMLInputElement)?.value + "#";
                        Escalationdata += (document.getElementById(`escalationContactCheckBox_${item.partnerEscalationInformationId}`) as HTMLInputElement)?.checked ? 'true' : 'false';
                        Escalationdata += "$";

                        console.log();

                        console.log("before concat", Escalationdata);

                    });

                    drlpContactArry?.forEach((item) => {
                        DRLPdata += (document.getElementById(`drlpContactName_${item.partnerDRLPContactInformationId}`) as HTMLInputElement)?.value + "#";
                        DRLPdata += (document.getElementById(`drlpContactEmail_${item.partnerDRLPContactInformationId}`) as HTMLInputElement)?.value + "#";
                        DRLPdata += (document.getElementById(`drlpContactPhone_${item.partnerDRLPContactInformationId}`) as HTMLInputElement)?.value + "#";
                        DRLPdata += (document.getElementById(`drlpContactCheckBox_${item.partnerDRLPContactInformationId}`) as HTMLInputElement)?.checked ? 'true' : 'false';
                        DRLPdata += "$";
                        console.log();

                        console.log("before concat", DRLPdata);

                    });

                    data.contactInformation = s;
                    data.accontInformation = Accountdata;
                    data.licenseeContactInformation = Licenseedata;
                    data.slaAdminInformation = SLAAdmindata;
                    data.escalationContactInformation = Escalationdata;
                    data.dRLPContactInformation = DRLPdata;


                    data.contactInformation = s;

                    setloading(true);
                    formData.append("data", JSON.stringify(data));
                    console.log("Final DAta", formData);
                    fetch(
                        config.url.API_URL + '/Partners/AddEdit',
                        {
                            method: 'POST',

                            headers: new Headers({
                                // 'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            // body: JSON.stringify(data) 
                            body: formData  // <-- Post parameters

                        })
                        .then((response) => {
                            console.log(response);

                            setloading(false);
                            setSubmitting(false);
                            if (response.ok) {
                                return response.json();
                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            // Do something with the response
                            navigate("/Partners");

                            setloading(false);

                        }).catch((error) => {
                            console.log(error)
                        });

                }} >
                {({ values, isSubmitting, setFieldValue }) => (
                    <React.Fragment>
                        <Form  >

                            <div className="table-responsive main-form ">
                                <div className='mb-2' >
                                    {params.partnersId === '0' ?
                                        <h2 style={{ marginLeft: "3vw" }} className='card-title' >New Customer Submission</h2> :
                                        <h2 style={{ marginLeft: "3vw" }} className='card-title'>Edit Customer</h2>
                                    }

                                    <Grid container spacing={1} xs={12}  >
                                        <Grid item sm={12} md={12} lg={12} xl={12} marginLeft="3vw" marginBottom="50px">
                                            <Grid item xs={12} display='flex' flexDirection='row'>
                                                <span className="sub-card-title" >General Information</span>
                                            </Grid>
                                            <Grid container spacing={2} xs={12} >
                                                {/* <Grid item xs={12}>
                                                    <FormControl sx={{ width: '100%', marginBottom: 2 }} >

                                                        <FMSelectPartnerType name="partnerTypeId" />
                                                    </FormControl>
                                                </Grid> */}
                                                <Grid item xs={12}>
                                                    <FormHelperText className="formtexthelper-heading" >CustomerName</FormHelperText>
                                                    <FormControl sx={{ width: '100%', marginBottom: 2 }} >
                                                        <FMTextField name="customerName" autoFocus margin="dense" type="text" fullWidth variant="outlined" />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormHelperText className="formtexthelper-heading" >Street Address</FormHelperText>
                                                    <FormControl sx={{ width: '100%', marginBottom: 2 }} >
                                                        <FMTextField name="streetAddress" type="Text" fullWidth variant="outlined" />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} display='flex' flexDirection='row'>
                                                    <Grid item xs={12}><FormHelperText className="formtexthelper-heading" >Suit or Unit</FormHelperText>
                                                        <FormControl sx={{ width: '100%' }} >
                                                            <FMTextField name="suitOrUnit" label="" type="Text" fullWidth variant="outlined" />
                                                        </FormControl>
                                                    </Grid>

                                                </Grid>

                                                <Grid item xs={12} display='flex' flexDirection='row'>

                                                    <Grid item xs={5.8}>
                                                        <FormControl sx={{ width: '100%', marginBottom: 2 }}>
                                                            <FormHelperText className="formtexthelper-heading" >City</FormHelperText>
                                                            <FMTextField name="city" label="" autoFocus margin="dense" type="text" variant="outlined" />
                                                        </FormControl >
                                                    </Grid>
                                                    <Grid item xs={0.4}></Grid>
                                                    <Grid item xs={5.8}><FormHelperText className="formtexthelper-heading" >State</FormHelperText>
                                                        <FormControl sx={{ width: '100%', marginBottom: 2 }}>
                                                            <FMSelectStateCustom name='state'></FMSelectStateCustom>
                                                            {/* <FMSelectState name="state" /> */}
                                                        </FormControl >
                                                    </Grid>

                                                </Grid>
                                                <Grid item xs={12} display='flex' flexDirection='row'>

                                                    <Grid item xs={5.8}>
                                                        <FormHelperText className="formtexthelper-heading" >ZIP</FormHelperText>
                                                        <FormControl sx={{ width: '100%', marginBottom: 4 }}>
                                                            <FMTextField name="zipCode" label="" InputLabelProps={{ shrink: true }} autoFocus margin="dense" type="text" variant="outlined" />
                                                        </FormControl >
                                                    </Grid>
                                                    <Grid item xs={0.4}></Grid>

                                                    <Grid item xs={5.8}>
                                                        <FormControl sx={{ width: '100%', marginBottom: 4 }}>
                                                            <FormHelperText className="formtexthelper-heading" >National Producer Number</FormHelperText>
                                                            <FMTextField name="npn" label="" autoFocus margin="dense" InputLabelProps={{ shrink: true }} type="text" variant="outlined" />
                                                        </FormControl >
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} display='flex' flexDirection='row'>

                                                    <Grid item xs={5.8}>
                                                        <FormControl sx={{ width: '100%', marginBottom: 2 }} >
                                                            <FMSelectActiveInactive name="status" />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={0.4}></Grid>
                                                    {/* <Grid item xs={6} display='flex' flexDirection='row'>
                                                        <Grid item xs={2}>
                                                            <FormControl sx={{ width: '100%', marginBottom: 2 }}  >
                                                                <div className='d-flex align-items-center' > <Checkbox name="isDiligentSearch" onChange={handleDiligentSearch}
                                                                    checked={DiligentSearch === true ? true : false
                                                                    }

                                                                /> <span>DiligentSearch</span></div>

                                                            </FormControl>
                                                        </Grid>
                                                    </Grid> */}
                                                    <Grid item xs={5.8}>
                                                        <FormControl sx={{ width: '100%', marginBottom: 2 }} >
                                                            <FMSelectTrueFalseCustom name="generateSL1" />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>


                                                {selectedValue === 'true' ?
                                                    <Grid item xs={12} spacing={2} display='flex' flexDirection='row'>

                                                        <Grid item xs={5.8}>
                                                            <div className="card-body card-body-dashboard">
                                                                <GenericStyledDropZone key={1} />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={0.4}></Grid>
                                                        <Grid item xs={5.8}>
                                                            <FormControl sx={{ width: '100%' }} >
                                                                <img src={InitialData.signature} alt="Blob Image" />
                                                            </FormControl>
                                                        </Grid>


                                                    </Grid>
                                                    : ""
                                                }

                                            </Grid>

                                        </Grid>

                                        {/* <Grid item sm={12} md={12} lg={12} xl={4} marginLeft="3vw" marginBottom="50px">
                                            <Grid item xs={12} display='flex' >
                                                <span className="sub-card-title" >States</span>
                                            </Grid>
                                            <Grid item xs={12}  >
                                                <div className="card overflow-auto  state-box" style={{ height: "60vh", margin: "0px" }}>
                                                    <div className=" " >
                                                        <Grid item xs={12} container spacing={3} marginTop={"5px"} direction="row" justifyContent="space-between" marginLeft={4}> */}
                                        {/* <Grid item xs={4}>
                                                                <Button variant="contained" color="primary" onClick={selectAll} sx={{ width: "100%" }} >Select-All</Button>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Button variant="contained" color="primary" onClick={disSelectAll} sx={{ width: "100%" }} >Deselect-All </Button>
                                                            </Grid> */}
                                        {/* </Grid>
                                                        <StateCheckBoxAgencyDetails selectedStates={selectedStates} States={States} handleCheckBoxChange={handleCheckBoxChange} />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>
                                    <Grid container spacing={1} xs={12} >
                                        <Grid container spacing={2} xs={12} marginLeft="3.3vw" marginBottom="50px">
                                            <Grid container spacing={2} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span className="sub-card-title">Notes</span>
                                                </Grid>
                                            </Grid>

                                            <Grid container xs={12}>
                                                <TextField
                                                    id="outlined-multiline-flexible"
                                                    multiline
                                                    maxRows={4}
                                                    sx={{
                                                        width: '100%',
                                                        '& .MuiInputBase-inputMultiline': {
                                                            minHeight: '100px',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} xs={12} >
                                        <Grid container spacing={2} xs={12} marginLeft="3.3vw" marginBottom="50px">
                                            <Grid container spacing={2} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span className="sub-card-title">Services</span>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} xs={12} className='d-flex align-items-center '>
                                                <Grid item xl={4} lg={4} md={6} xs={12} >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                            disabled={!isUserAdmin}
                                                                onChange={(e) => {
                                                                    e.target.checked = e.target.checked ? true : false;
                                                                    e.target.value = e.target.checked ? "true" : "false";
                                                                }}
                                                            />
                                                        }
                                                        label={"Surplus Lines Tax Filings & Reporting"}
                                                    />
                                                </Grid>
                                                <Grid item xl={4} lg={4} md={6} xs={12} >
                                                    <FormControl sx={{ width: '100%', marginBottom: 2 }}>
                                                        <div className='d-flex align-items-center'>
                                                            <Checkbox 
                                                                name="isDiligentSearch" 
                                                                onChange={handleDiligentSearch}
                                                                checked={DiligentSearch === true ? true : false}
                                                                disabled={!isUserAdmin} // Added the disabled condition
                                                            />
                                                            <span>Diligent Searches</span>
                                                        </div>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xl={4} lg={4} md={6} xs={12} >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                            disabled={!isUserAdmin}
                                                                onChange={(e) => {
                                                                    e.target.checked = e.target.checked ? true : false;
                                                                    e.target.value = e.target.checked ? "true" : "false";
                                                                }}
                                                            />
                                                        }
                                                        label={"Consulting"}
                                                    />
                                                </Grid>
                                                <Grid item xl={4} lg={4} md={6} xs={12} >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                            disabled={!isUserAdmin}
                                                                onChange={(e) => {
                                                                    e.target.checked = e.target.checked ? true : false;
                                                                    e.target.value = e.target.checked ? "true" : "false";
                                                                }}
                                                            />
                                                        }
                                                        label={"Licensing"}
                                                    />
                                                </Grid>
                                                <Grid item xl={4} lg={4} md={6} xs={12}  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                            disabled={!isUserAdmin}
                                                                onChange={(e) => {
                                                                    e.target.checked = e.target.checked ? true : false;
                                                                    e.target.value = e.target.checked ? "true" : "false";
                                                                }}
                                                            />
                                                        }
                                                        label={"Other"}
                                                    />
                                                    <TextField className='text-field-1' disabled={!isUserAdmin} autoFocus type="text" fullWidth variant="outlined" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} xs={12} >
                                        <Grid container spacing={2} xs={12} marginLeft="3.3vw" marginBottom="50px">
                                            <Grid container spacing={2} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span className="sub-card-title">Filing Contact Information</span>
                                                    <Button className="btn active-btn-blue" type="button" style={{ marginLeft: '50px', marginTop: '15px' }} onClick={() => { setFilingContactArry(filingContactArry => [...filingContactArry, initFilingContactInformation]) }}>Add More</Button>
                                                </Grid>
                                            </Grid>
                                            {!isContactInfoLoading ?
                                                filingContactArry?.map((contact) => (
                                                    <React.Fragment key={contact.partnerFilingContactInformationId}>
                                                        <Grid container spacing={2} xs={12} className='d-flex align-items-center '>
                                                            <Grid item xl={2.3} lg={2.3} md={6} xs={12} >

                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'> Name</FormHelperText>
                                                                    {/* <FMTextField name={"filingContactName_" + index} id={"filingContactName_" + index} defaultValue={filingContactArry[index].contactName} autoFocus margin="dense" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} /> */}
                                                                    <TextField name={`filingContactName_${contact.partnerFilingContactInformationId}`} id={`filingContactName_${contact.partnerFilingContactInformationId}`} defaultValue={contact.contactName} className='text-field-1' autoFocus type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={2.2} lg={2.2} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'>Email</FormHelperText>
                                                                    <TextField className='text-field-1' name={`filingContactEmail_${contact.partnerFilingContactInformationId}`} id={`filingContactEmail_${contact.partnerFilingContactInformationId}`} defaultValue={contact.contactEmail} autoFocus label="" type="email" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={2.3} lg={2.3} md={6} xs={12}>
                                                                <FormControl style={{ marginLeft: "3px" }} sx={{ width: '100.5%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'> Phone Number</FormHelperText>
                                                                    <TextField className='text-field-1' name={`filingContactPhone_${contact.partnerFilingContactInformationId}`} id={`filingContactPhone_${contact.partnerFilingContactInformationId}`} defaultValue={contact.contactPhone} autoFocus InputLabelProps={{ shrink: true }} type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={2.2} lg={2.2} md={6} xs={12}>

                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading '>Escalation</FormHelperText>
                                                                    <FMSelectEscalation name={'escalation'} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={0.1}></Grid>
                                                            <Grid item xl={2} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ display: 'flex', alignItems: 'center', width: '100%' }} className='Notifications-label'>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name={`filingContactCheckBox_${contact.partnerFilingContactInformationId}`}
                                                                                    id={`filingContactCheckBox_${contact.partnerFilingContactInformationId}`}
                                                                                    defaultChecked={contact.isAutoNotification}
                                                                                    onChange={(e) => {
                                                                                        e.target.checked = e.target.checked ? true : false;
                                                                                        e.target.value = e.target.checked ? "true" : "false";
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={"Auto Notifications"}
                                                                        />
                                                                        <IconButton
                                                                            color="error"
                                                                            onClick={() => handleDeleteFilingContact(contact.partnerFilingContactInformationId)}
                                                                        >
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </div>
                                                                </FormControl>
                                                            </Grid>

                                                        </Grid>
                                                    </React.Fragment>

                                                ))
                                                : ""}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} xs={12} >
                                        <Grid container spacing={2} xs={12} marginLeft="3.3vw" marginBottom="50px">
                                            <Grid container spacing={2} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span className="sub-card-title">Accounting Contact Information</span>
                                                    <Button className="btn active-btn-blue" type="button" style={{ marginLeft: '35px', marginTop: '20px' }} onClick={() => { setAcountingContactArry(acountingContactArry => [...acountingContactArry, initAcountingContactInformation]) }}>Add More</Button>
                                                </Grid>
                                            </Grid>
                                            {!isaccountInfoLoading ?
                                                acountingContactArry?.map((contact) => (
                                                    <React.Fragment key={contact.partnerAccountingInformationId}>
                                                        <Grid container spacing={2} xs={12} className='align-items-center '>
                                                            <Grid item xl={2.3} lg={2.3} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'>Name</FormHelperText>
                                                                    <TextField className='text-field-1' name={`accountingContactName_${contact.partnerAccountingInformationId}`} id={`accountingContactName_${contact.partnerAccountingInformationId}`} defaultValue={contact.contactName} autoFocus label="" type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={2.2} lg={2.2} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'>Email</FormHelperText>
                                                                    <TextField className='text-field-1' name={`accountingContactEmail_${contact.partnerAccountingInformationId}`} id={`accountingContactEmail_${contact.partnerAccountingInformationId}`} defaultValue={contact.contactEmail} autoFocus label="" type="email" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={2.3} lg={2.3} md={6} xs={12}>

                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading '>Phone Number</FormHelperText>
                                                                    <TextField className='text-field-1' name={`accountingContactPhone_${contact.partnerAccountingInformationId}`} id={`accountingContactPhone_${contact.partnerAccountingInformationId}`} defaultValue={contact.contactPhone} autoFocus margin="dense" label="" type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={2.2} lg={2.2} md={6} xs={12}>

                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading '>Escalation</FormHelperText>
                                                                    <FMSelectEscalation name={'escalation'} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={0.1}></Grid>
                                                            <Grid item xl={2} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ display: 'flex', alignItems: 'center', width: '100%' }} className='Notifications-label'>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name={`filingContactCheckBox_${contact.partnerAccountingInformationId}`}
                                                                                    id={`filingContactCheckBox_${contact.partnerAccountingInformationId}`}
                                                                                    defaultChecked={contact.isAutoNotification}
                                                                                    onChange={(e) => {
                                                                                        e.target.checked = e.target.checked ? true : false;
                                                                                        e.target.value = e.target.checked ? "true" : "false";
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={"Auto Notifications"}
                                                                        />
                                                                        <IconButton
                                                                            color="error"
                                                                            onClick={() => handleDeleteAccountContact(contact.partnerAccountingInformationId)}
                                                                        >
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </div>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>

                                                    </React.Fragment>

                                                ))
                                                : ""}
                                        </Grid>
                                    </Grid>

                                    {/* <Grid container spacing={1} xs={12}>
                                        <Grid container spacing={2} xs={12} marginLeft="3.3vw" marginBottom="50px">
                                            <Grid container spacing={2} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span className="sub-card-title">Licensee(s) Contact Information</span>
                                                    <button className="btn active-btn-blue" type="button" style={{ marginLeft: '15px', marginTop: '20px' }} onClick={() => { setLicenseesContactArry(licenseesContactArry => [...licenseesContactArry, initLicenseeContactInformation]) }}>Add More</button>
                                                </Grid>
                                            </Grid>
                                            {!isLicenseesInfoLoading ?
                                                licenseesContactArry.map((contact) => (
                                                    <React.Fragment key={contact.partnerLincenseeInformationId}>
                                                        <Grid container spacing={2} xs={12} className='d-flex align-items-center'>
                                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }}>
                                                                    <FormHelperText className='formtexthelper-heading'> Name</FormHelperText>
                                                                    <TextField name={`licenseeContactName_${contact.partnerLincenseeInformationId}`} id={`licenseeContactName_${contact.partnerLincenseeInformationId}`} defaultValue={contact.contactName} className='text-field-1' autoFocus margin="dense" type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }}>
                                                                    <FormHelperText className='formtexthelper-heading'>Email</FormHelperText>
                                                                    <TextField className='text-field-1' name={`licenseeContactEmail_${contact.partnerLincenseeInformationId}`} id={`licenseeContactEmail_${contact.partnerLincenseeInformationId}`} defaultValue={contact.contactEmail} autoFocus margin="dense" label="" type="email" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                                <FormControl style={{ marginLeft: "3px" }} sx={{ width: '100%' }}>
                                                                    <FormHelperText className='formtexthelper-heading'> Phone Number</FormHelperText>
                                                                    <TextField className='text-field-1' name={`licenseeContactPhone_${contact.partnerLincenseeInformationId}`} id={`licenseeContactPhone_${contact.partnerLincenseeInformationId}`} defaultValue={contact.contactPhone} autoFocus margin="dense" InputLabelProps={{ shrink: true }} type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={0.1}></Grid>
                                                            <Grid item xl={2} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ display: 'flex', alignItems: 'center', width: '100%' }} className='Notifications-label'>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name={`filingContactCheckBox_${contact.partnerLincenseeInformationId}`}
                                                                                    id={`filingContactCheckBox_${contact.partnerLincenseeInformationId}`}
                                                                                    defaultChecked={contact.isAutoNotification}
                                                                                    onChange={(e) => {
                                                                                        e.target.checked = e.target.checked ? true : false;
                                                                                        e.target.value = e.target.checked ? "true" : "false";
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={"Auto Notifications"}
                                                                        />
                                                                        <IconButton color="error" onClick={() => handleDeleteLicensee(contact.partnerLincenseeInformationId)}>
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </div>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                ))
                                                : ""}
                                        </Grid>
                                    </Grid> */}
                                    {/* Grid for SLA Contact Info */}
                                    <Grid container spacing={1} xs={12} >
                                        <Grid container spacing={2} xs={12} marginLeft="3.3vw" marginBottom="50px">
                                            <Grid container spacing={2} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span className="sub-card-title">SLA Contact Information</span>
                                                    <Button className="btn active-btn-blue" type="button" style={{ marginLeft: '65px', marginTop: '20px' }} onClick={() => { setSLAAdminContactArry(slaAdminContactArry => [...slaAdminContactArry, initSLAAdminContactInformation]) }}>Add More</Button>
                                                </Grid>
                                            </Grid>
                                            {!isSLAAdminInfoLoading ?
                                                slaAdminContactArry?.map((contact) => (
                                                    <React.Fragment key={contact.partnerSLaAdminContactInformationId}>
                                                        <Grid container spacing={2} xs={12} className='d-flex align-items-center '>
                                                            <Grid item xl={3} lg={3} md={6} xs={12} >

                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'> Name</FormHelperText>
                                                                    {/* <FMTextField name={"filingContactName_" + index} id={"filingContactName_" + index} defaultValue={filingContactArry[index].contactName} autoFocus margin="dense" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} /> */}
                                                                    <TextField name={`slaAdminContactName_${contact.partnerSLaAdminContactInformationId}`} id={`slaAdminContactName_${contact.partnerSLaAdminContactInformationId}`} defaultValue={contact.contactName} className='text-field-1' autoFocus margin="dense" type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'>Email</FormHelperText>
                                                                    <TextField className='text-field-1' name={`slaAdminContactEmail_${contact.partnerSLaAdminContactInformationId}`} id={`slaAdminContactEmail_${contact.partnerSLaAdminContactInformationId}`} defaultValue={contact.contactEmail} autoFocus margin="dense" label="" type="email" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'> Phone Number</FormHelperText>
                                                                    <TextField className='text-field-1' name={`slaAdminContactPhone_${contact.partnerSLaAdminContactInformationId}`} id={`slaAdminContactPhone_${contact.partnerSLaAdminContactInformationId}`} defaultValue={contact.contactPhone} autoFocus margin="dense" InputLabelProps={{ shrink: true }} type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={0.1}></Grid>
                                                            <Grid item xl={2} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ display: 'flex', alignItems: 'center', width: '100%' }} className='Notifications-label'>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name={`filingContactCheckBox_${contact.partnerSLaAdminContactInformationId}`}
                                                                                    id={`filingContactCheckBox_${contact.partnerSLaAdminContactInformationId}`}
                                                                                    defaultChecked={contact.isAutoNotification}
                                                                                    onChange={(e) => {
                                                                                        e.target.checked = e.target.checked ? true : false;
                                                                                        e.target.value = e.target.checked ? "true" : "false";
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={"Auto Notifications"}
                                                                        />
                                                                        <IconButton
                                                                            color="error"
                                                                            onClick={() => handleDeleteSLAAdminContact(contact.partnerSLaAdminContactInformationId)}
                                                                        >
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </div>
                                                                </FormControl>
                                                            </Grid>

                                                        </Grid>
                                                    </React.Fragment>

                                                ))
                                                : ""}
                                        </Grid>
                                    </Grid>

                                    {/* Grid for Escalation Contact Info */}
                                    {/* <Grid container spacing={1} xs={12} >
                                        <Grid container spacing={2} xs={12} marginLeft="3.3vw" marginBottom="50px">
                                            <Grid container spacing={2} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span className="sub-card-title">Escalation Contact Information</span>
                                                    <button className="btn active-btn-blue" type="button" style={{ marginLeft: '20px', marginTop: '20px' }} onClick={() => { setEscalationContactArry(escalationContactArry => [...escalationContactArry, initEscalationContactInformation]) }}>Add More</button>
                                                </Grid>
                                            </Grid>
                                            {!isEscalationInfoLoading ?
                                                escalationContactArry?.map((contact) => (
                                                    <React.Fragment key={contact.partnerEscalationInformationId}>
                                                        <Grid container spacing={2} xs={12} className='d-flex align-items-center '>
                                                            <Grid item xl={3} lg={3} md={6} xs={12} >

                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'> Name</FormHelperText>
                                                                    <TextField name={`escalationContactName_${contact.partnerEscalationInformationId}`} id={`escalationContactName_${contact.partnerEscalationInformationId}`} defaultValue={contact.contactName} className='text-field-1' autoFocus margin="dense" type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'>Email</FormHelperText>
                                                                    <TextField className='text-field-1' name={`escalationContactEmail_${contact.partnerEscalationInformationId}`} id={`escalationContactEmail_${contact.partnerEscalationInformationId}`} defaultValue={contact.contactEmail} autoFocus margin="dense" label="" type="email" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                                <FormControl  sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'> Phone Number</FormHelperText>
                                                                    <TextField className='text-field-1' name={`escalationContactPhone_${contact.partnerEscalationInformationId}`} id={`escalationContactPhone_${contact.partnerEscalationInformationId}`} defaultValue={contact.contactPhone} autoFocus margin="dense" InputLabelProps={{ shrink: true }} type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={0.1}></Grid>
                                                            <Grid item xl={2} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ display: 'flex', alignItems: 'center', width: '100%' }} className='Notifications-label'>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name={`filingContactCheckBox_${contact.partnerEscalationInformationId}`}
                                                                                    id={`filingContactCheckBox_${contact.partnerEscalationInformationId}`}
                                                                                    defaultChecked={contact.isAutoNotification}
                                                                                    onChange={(e) => {
                                                                                        e.target.checked = e.target.checked ? true : false;
                                                                                        e.target.value = e.target.checked ? "true" : "false";
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={"Auto Notifications"}
                                                                        />
                                                                        <IconButton
                                                                            color="error"
                                                                            onClick={() => handleDeleteEscalationContact(contact.partnerEscalationInformationId)}
                                                                        >
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </div>
                                                                </FormControl>
                                                            </Grid>

                                                        </Grid>
                                                    </React.Fragment>

                                                ))
                                                : ""}
                                        </Grid>
                                    </Grid> */}

                                    {/* Grid for DRLP Contact Info */}
                                    <Grid container spacing={1} xs={12} >
                                        <Grid container spacing={2} xs={12} marginLeft="3.3vw" marginBottom="50px">
                                            <Grid container spacing={2} xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span className="sub-card-title">DRLP Contact Information</span>
                                                    <Button className="btn active-btn-blue" type="button" style={{ marginLeft: '55px', marginTop: '20px' }} onClick={() => { setDRLPContactArry(drlpContactArry => [...drlpContactArry, initDRLPContactInformation]) }}>Add More</Button>
                                                </Grid>
                                            </Grid>
                                            {!isDRLPInfoLoading ?
                                                drlpContactArry?.map((contact) => (
                                                    <React.Fragment key={contact.partnerDRLPContactInformationId}>
                                                        <Grid container spacing={2} xs={12} className='d-flex align-items-center '>
                                                            <Grid item xl={2.3} lg={2.3} md={6} xs={12} >

                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'> Name</FormHelperText>
                                                                    <TextField name={`drlpContactName_${contact.partnerDRLPContactInformationId}`} id={`drlpContactName_${contact.partnerDRLPContactInformationId}`} defaultValue={contact.contactName} className='text-field-1' autoFocus margin="dense" type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={2.2} lg={2.2} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'>Email</FormHelperText>
                                                                    <TextField className='text-field-1' name={`drlpContactEmail_${contact.partnerDRLPContactInformationId}`} id={`drlpContactEmail_${contact.partnerDRLPContactInformationId}`} defaultValue={contact.contactEmail} autoFocus margin="dense" label="" type="email" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={2.3} lg={2.3} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'> Phone Number</FormHelperText>
                                                                    <TextField className='text-field-1' name={`drlpContactPhone_${contact.partnerDRLPContactInformationId}`} id={`drlpContactPhone_${contact.partnerDRLPContactInformationId}`} defaultValue={contact.contactPhone} autoFocus margin="dense" InputLabelProps={{ shrink: true }} type="text" fullWidth variant="outlined" sx={{ marginTop: 0, width: '100%' }} />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xl={2.2} lg={2.2} md={6} xs={12}>
                                                                <FormControl sx={{ width: '100%' }} >
                                                                    <FormHelperText className='formtexthelper-heading'>State</FormHelperText>
                                                                    <FMSelectMultiState name='State' />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={0.1}></Grid>
                                                            <Grid item xl={2} lg={3} md={6} xs={12}>
                                                                <FormControl sx={{ display: 'flex', alignItems: 'center', width: '100%' }} className='Notifications-label'>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name={`filingContactCheckBox_${contact.partnerDRLPContactInformationId}`}
                                                                                    id={`filingContactCheckBox_${contact.partnerDRLPContactInformationId}`}
                                                                                    defaultChecked={contact.isAutoNotification}
                                                                                    onChange={(e) => {
                                                                                        e.target.checked = e.target.checked ? true : false;
                                                                                        e.target.value = e.target.checked ? "true" : "false";
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={"Auto Notifications"}
                                                                        />
                                                                        <IconButton
                                                                            color="error"
                                                                            onClick={() => handleDeleteDRLPContact(contact.partnerDRLPContactInformationId)}
                                                                        >
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </div>
                                                                </FormControl>
                                                            </Grid>

                                                        </Grid>
                                                    </React.Fragment>

                                                ))
                                                : ""}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} xs={12} >
                                        <Grid container spacing={2} xs={12} marginLeft="3vw" marginBottom="50px">
                                            <Grid container d-flex justifyContent={"space-between"} alignItems={"center"} >
                                                <Grid container xs={12}  >
                                                    <span className="sub-card-title " style={{ marginLeft: "16px" }} >Integrations</span>
                                                    {/* <h5 className="text-center text-secondary">Filing Contact Information</h5> */}
                                                </Grid>
                                            </Grid>
                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >Integration Type</FormHelperText>
                                                    <FMSelectPartnerIntegrationType name="partnerIntegrationType" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                <FormControl sx={{ width: '100.2%' }}>
                                                    <FormHelperText className='formtexthelper-heading'  >Agency/Policy Management System</FormHelperText>
                                                    <FMTextField name="agencyPolicyManagementSystem" disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))} label="" autoFocus margin="dense" type="text" variant="outlined" sx={{ m: 2 }} />

                                                </FormControl >
                                            </Grid>

                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <FormHelperText className='formtexthelper-heading'>Generate Api</FormHelperText>
                                                    <FMTextField
                                                        name="apiToken"
                                                        label=""
                                                        autoFocus
                                                        margin="dense"
                                                        type="text"
                                                        variant="outlined"
                                                        sx={{ m: 2 }}
                                                    // value={GeneratedApi}
                                                    // onChange={handleInputChange}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item mt-2>
                                                <Button className='active-btn-blue mt-4' type='button' onClick={() => handleButtonClick(setFieldValue)}>Generate Key</Button>
                                            </Grid>
                                        </Grid>

                                        {params.partnersId === '0' ?
                                            <Grid container spacing={2} xs={12} marginLeft="3vw" marginBottom="50px">

                                            </Grid>
                                            :
                                            ""

                                        }
                                        <Grid container spacing={2} xs={12} marginLeft="3vw" marginBottom="50px">
                                            <Grid item xs={12} display='flex' flexDirection='row'>
                                                <span className="sub-card-title" >Payments</span>
                                                {/* <h5 className="text-center text-secondary">Payments</h5> */}
                                            </Grid>
                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading'>Payment Type</FormHelperText>
                                                    <FMSelectPartnerPaymentType name="paymentType" />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} xs={12} marginLeft="3vw" marginBottom="50px">
                                            <Grid item xs={12} display='flex' flexDirection='row'>
                                                <span className="sub-card-title" >CA SLA Information</span>
                                                {/* <h5 className="text-center text-secondary">Payments</h5> */}
                                            </Grid>
                                            <Grid item xl={3} lg={3} md={6} xs={12}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >SLA Username</FormHelperText>
                                                    <FMTextField name="slaUserName" disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))} autoComplete='off' autoFocus margin="dense" type="text" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xl={3} lg={3} md={6} xs={12}><FormHelperText className='formtexthelper-heading' >SLA Broker Name</FormHelperText>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FMTextField name="slaBrokerNumber" disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))} autoComplete='off' autoFocus margin="dense" InputLabelProps={{ shrink: true }} type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xl={3} lg={3} md={6} xs={12}><FormHelperText className='formtexthelper-heading' >SLA Access Token </FormHelperText>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FMTextField name="slaApiKey" disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))} autoComplete='off' autoFocus margin="dense" type="text" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <div className=" ">
                                        <div className="">
                                            <Grid container spacing={2} xs={12} >
                                                <Grid container spacing={2} xs={12} marginLeft="3vw" marginBottom="50px">
                                                    <Grid item xs={12} >
                                                        <LicenseMatrix setLicenseesContactArry={setLicenseesContactArry} licenseesContactArry={licenseesContactArry} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className=" ">
                                        <div className="">
                                            <Grid container spacing={2} xs={12} >
                                                <Grid container spacing={2} xs={12} marginLeft="3vw" marginBottom="50px">
                                                    <Grid item xs={12} >
                                                        <PartnerTaxesAndFeesList setPartnerTaxesAndFee={setPartnerTaxesAndFee} PartnerTaxesAndFeeCL={PartnerTaxesAndFee} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>


                                    {/* <Grid container spacing={2} xs={12} >
                                        <Grid container spacing={2} xs={12} marginLeft="3vw" marginBottom="50px">
                                            <Grid item xs={12} display='flex' flexDirection='row'>
                                                <span className="sub-card-title" >Carriers</span>
                                            </Grid>

                                            <Grid item xs={12}   >
                                                <InsuranceCompanyCheckBox PartnerInsuranceCompany={PartnerInsuranceCompany} handleCheckBoxChange={handleInsuranceCompanyCheckBoxChange} selectedPartnerInsuranceCompany={selectedPartnerInsuranceCompany} />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}

                                    <Grid container marginLeft={""}  >
                                        <Button className='btn active-btn-blue' style={{ marginLeft: "3vw" }} disabled={user?.userRoles.split(",").includes("MISC Filer")} type='submit'   >Save</Button>
                                        {loading && <CircularProgress style={{ margin: 2 }} />}
                                    </Grid>
                                </div>
                            </div>
                        </Form>

                        <AddEditPartnerAgencyDetails partnerAgencyDetail={PartnerAgencyData} setPartnerAgencyDetailList={setPartnerAgencyDetailList} Open={Open} setopen={setOpen} partnerAgencyDetailList={partnerAgencyDetailList} addselectedStates={addselectedStates} removeSelectedStates={RemoveSelected} stateName={StateName} />

                    </React.Fragment>
                )}


            </Formik>
        </React.Fragment>
        /* </div>
      </div>
  </div>
</div>
</div>
</div>
</div>
<RightNav />

</div>
</div>
</div>

</main>
</section> */



    )
}
